

<template>
	<div :class="[{active : active},'site-menu']">
		<div class="site-menu__primary fullheight-col">
			<div class="container-fluid">
				<!-- Top nav -->
				<div class="site-menu__menu">
					<div class="row">
						<div class="col-md-12">
							<transition-group
								name="staggered-slideup"
								class="site-menu__menu__inner"
								tag="div"
								@before-enter="staggeredBeforeEnter"
								@enter="staggeredEnter"
								@leave="staggeredLeave"
								>
							<a
								v-for="(item, index) in tempMenuItems"
								:key="index"
								:data-index="index"
								:href="item.url"
									>
								<span>
									{{item.name}}
								</span>
							</a>
						</transition-group>
						</div>
					</div>
				</div>
				<!-- Top nav END -->
			</div>
		</div>
		<transition name="slideleft">
			<div
				v-if="currentPage == 'services'"
				:class="[{ active : currentPage == 'services'},'site-menu__page fullheight-col']"
					>
				<div class="site-menu__page__inner">
					<div class="vertically-align">
						<div class="site-menu__block-container">
							<a class="block" href="#">
								<div class="block__img">
									<div class="object-fit object-fit--contain">
										<img src="/wp-content/themes/demo/assets/images/sections/menu/block-1.png">
									</div>
								</div>
								<p class="p-bold">
									Brand Strategy and Activation
								</p>
							</a>
							<a class="block" href="#">
								<div class="block__img">
									<div class="object-fit object-fit--contain">
										<img src="/wp-content/themes/demo/assets/images/sections/menu/block-2.png">
									</div>
								</div>
								<p class="p-bold">
									Communication Strategy and Design
								</p>
							</a>
							<a class="block" href="#">
								<div class="block__img">
									<div class="object-fit object-fit--contain">
										<img src="/wp-content/themes/demo/assets/images/sections/menu/block-3.png">
									</div>
								</div>
								<p class="p-bold">
									Social and Behaviour Change Marketing
								</p>
							</a>
							<a class="block" href="#">
								<div class="block__img">
								</div>
								<p class="p-bold">
									Digital Strategy and Transformation
								</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<transition name="slideleft">
			<div
				v-if="currentPage == 'projects'"
				:class="[{ active : currentPage == 'projects'},'site-menu__page fullheight-col']"
				v-touch:swipe.right="onSwipe"
				>
				<lazyimg
					v-for="project in projects"
					:imageClass="[{active : project.name === currentProject},'site-menu__cover']"
					:imageSource="project.image"
				/>
				<div class="site-menu__page__inner">
					<div class="vertically-align">
						<div class="site-menu__block-container">
							<ul class="blank-list site-menu__list">
								<li v-for="project in projects">
									<a
										:href="project.url"
										:class="[{active : currentProject === project.name},'d-block']"
										@mouseover="onMouseOver(project.name)"
										v-touch="onTouch"
										>
										{{project.name}}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</transition>
		</div>
	</div>
</template>


<script>
import lazyimg from '../components/lazyImg';
import {eventBus} from '../entry';
import anime from "../../plugins/anime.min.js";
	export default {
		components : {
			lazyimg
		},
		data : function() {
			return {
				counter : 0, // For the rotate
				active : false,
				hover : false,
				currentPage : '',
				currentProject : '',
				currentSubMenuItem : '',
				subMenuItems : [
					'brand',
					'campaign',
					'digital'
				],
				menuItems : [
					{
						name : 'Services',
						url  : '/services/'
					},
					{
						name : 'Work',
						url  : '/work'
					},
					{
						name : 'Company',
						url  : '/company'
					},
					// {
					// 	name : 'Resources',
					// 	url  : '/resources'
					// },
					{
						name : 'Contact',
						url  : '/contact'
					}
				],
				tempMenuItems : []
			}
		},
		watch : {
			active : function() {
				if(this.active) {
					this.showMenu();
					eventBus.$emit('menu:active');
					document.getElementsByTagName('html')[0].classList.add('modal-active');
					document.getElementsByClassName('site-header')[0].classList.add('menu-mobile-active');
					this.removeDrift();
				} else {
					this.hideMenu();
					eventBus.$emit('menu:inactive');
					document.getElementsByTagName('html')[0].classList.remove('modal-active');
					document.getElementsByClassName('site-header')[0].classList.remove('menu-mobile-active');
					this.addDrift();
				}
			},
			pageActive : function() {
				if(this.pageActive) {
					eventBus.$emit('menupage:active');
				} else {
					eventBus.$emit('menupage:inactive');
				}
			}
		},
		computed : {
			pageActive : function() {
				if(this.currentPage) {
					return true;
				} else {
					return false;
				}
			}
		},
		methods : {
			addDrift : function() {
				var drift = document.getElementById('drift-widget');
				if(drift) {
					drift.classList.remove('drift-die');
				}
			},
			removeDrift : function() {
				var drift = document.getElementById('drift-widget');
				if(drift) {
					drift.classList.add('drift-die');
				}
			},
			showMenu : function() {
				this.tempMenuItems = this.menuItems;
			},
			hideMenu : function() {
				this.tempMenuItems = [];
			},
			staggeredBeforeEnter : function(el) {
				//el.style.opacity = 0
				//el.style.transform = 'translateY(' + 15 + 'px)';
			},
			staggeredEnter : function(el, done) {
				var delay = el.dataset.index * 100
				setTimeout(() => {
					anime({
						targets : el,
						opacity : 1,
						translateY : [15, 0],
						duration : 1000,
						complete : done,
						easing: 'easeOutSine'
					})
				}, delay);
	      
			},
			staggeredLeave : function(el, done) {
				// var delay = el.dataset.index * 100
	   //    anime({
    //       	targets : el,
    //       	opacity : 0,
    //       	translateY : 15,
    //       	complete : done
	   //        })
			},
			onMouseOver : function(name) {
				this.currentProject = name;
				var width = document.getElementsByTagName('body')[0].offsetWidth;
				if(width < 576) {
					this.projects.map((p, i) => {
						if(name === p.name) {
							this.counter = i;
						}
					});
				} else {
				}
			},
			onTouch : function() {
				//console.log('touched');
			},
			onSwipe : function(dir) {
				if(this.currentPage) {
					this.currentPage = '';
				}
			},
			rotate : function() {
				this.currentProject = this.projects[this.counter].name;
				this.counter += 1;
				if(this.counter >= this.projects.length) {
					this.counter = 0;
				}
				setInterval(() => {
					this.currentProject = this.projects[this.counter].name;
					this.counter += 1;
					if(this.counter >= this.projects.length) {
						this.counter = 0;
					}
				}, 5000);
			},
			onMouseOver : function(item) {
				this.currentSubMenuItem = item;
				this.hover = true;
			},
			onMouseLeave : function() {
				this.currentSubMenuItem = '';
				this.hover = false;
			}
		},
		created : function() {
			eventBus.$on('burgermenu:click', (overRide) => {
				if(!overRide) {
					if(this.active) {
						var width = document.getElementsByTagName('body')[0].offsetWidth;
						if(width < 992) {
							if(this.currentPage) { // If page is shown then go back
								this.currentPage = '';
							} else { // Close the menu
								this.active = false;
							}
						} else {
							this.currentPage = '';
							this.active = false;
						}
					} else { // Show the menu
						this.active = true;
					}
				}
			});
			eventBus.$on('menu:active', (obj) => {
				this.active = true;
			});
			eventBus.$on('menu:inactive', (obj) => {
				this.active = false;
			});
		},
		mounted : function() {
			var width = document.getElementsByTagName('body')[0].offsetWidth;
			if(width < 576) {
				this.rotate();
			}
		}
	}
</script>