

<template>
	<div class="container">
		<div class="row">
			<div
				v-for="(item, index) in items"
				:class="[{active : index === currentItemIndex},'col-8 offset-2 col-md-4 offset-md-0']"
				@click="currentItemIndex = index"
					>
				<div class="s-packages-footer__tab">
					<h3 class="h2">
						{{item.price}}
					</h3>
					<p class="p-small">
						+GST
					</p>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-10 offset-md-1">
				<h3 class="mb-30">
					What we'll cover
				</h3>
				<transition name="fade" mode="out-in">
					<div class="row" v-if="currentItem">
						<div
							v-for="l in currentItem.list"
							class="col-md-6"
								>
							<p class="list-item">
								{{l}}
							</p>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>


<script>

	export default {
		data : function() {
			return {
				items : [
					{
						price : '$60k',
						list : [
							'Brand auditing and research',
							'Brand personality and narrative',
							'Brand workshops',
							'Visual language and identity',
							'Brand positioning and values',
							'Guidelines and governance',
							'Brand architecture and naming',
							'Brand experience and activation'
						]
					},
					{
						price : '$40k',
					},
					{
						price : '$20k'
					}
				],
				currentItemIndex : 0
			}
		},
		computed : {
			currentItem : function() {
				var item = this.items[this.currentItemIndex];
				if(!item) {
					item = false;
				}
				return item;
			}
		},
		methods : {
		},
		mounted : function() {
		}
	}
</script>