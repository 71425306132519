var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "c-sprite", class: [_vm.imageState] },
    [
      _c(
        "div",
        {
          staticClass: "c-sprite__inner",
          style: _vm.computedInnerStyle,
          attrs: {
            "data-width": _vm.imageWidth,
            "data-height": _vm.imageHeight,
            "data-state": _vm.imageState,
          },
        },
        [
          _c("div", {
            ref: "imageEl",
            staticClass: "c-sprite__image",
            style: _vm.computedStyle,
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }