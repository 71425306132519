<template>
	<div>
		<h3>
			Book a 30-minute<br />
			qualification call with us
		</h3>
		<form>
			<div class="form-group">
				<input
					type="text"
					:class="[{ filled: form.firstName }]"
					v-model="form.firstName"
					required="true"
				/>
				<label>
					First Name
				</label>
			</div>
			<span class="error" v-if="errors.firstName == 1">
				First name is required
			</span>
			<div class="form-group">
				<input
					type="text"
					:class="[{ filled: form.lastName }]"
					v-model="form.lastName"
					required="true"
				/>
				<label>
					Last Name
				</label>
			</div>
			<span class="error" v-if="errors.lastName == 1">
				Last name is required
			</span>
			<div class="form-group">
				<input
					type="text"
					:class="[{ filled: form.organization }]"
					v-model="form.organization"
					required="true"
				/>
				<label>
					Organization
				</label>
			</div>
			<div class="form-group">
				<input
					type="text"
					:class="[{ filled: form.phone }]"
					v-model="form.phone"
					required="true"
				/>
				<label>
					Phone
				</label>
			</div>
			<div class="form-group">
				<input
					type="text"
					:class="[{ filled: form.email }]"
					v-model="form.email"
					required="true"
				/>
				<label>
					Email
				</label>
			</div>
			<span class="error" v-if="errors.email == 1">
				Email is required
			</span>
			<span class="error" v-if="errors.email == 2">
				You need to enter a valid email address
			</span>
			<div class="form-group">
				<p>
					Have you applied for Google Grants in the past?
				</p>
			</div>
			<div class="form-group">
				<select
					:class="[{ filled: form.googleGrants }]"
					v-model="form.googleGrants"
				>
					<option :value="opt.title" v-for="opt in form.googleGrantsOptions">
						{{ opt.title }}
					</option>
				</select>
				<label>
					Select an option
				</label>
				<div class="icon icon-sm">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289Z"
							fill="#FA1855"
						/>
					</svg>
				</div>
				<span class="error" v-if="errors.googleGrants == 1">
					Select a Google Grants option
				</span>
			</div>
			<div class="form-group">
				<button
					:class="['btn', 'btn-primary', { disabled: loading }]"
					@click.prevent="onSubmit"
				>
					Book my FREE qualification call
				</button>
				<p class="p-small mt-15 error" v-if="error">
					Something went wrong, please try again
				</p>
			</div>
		</form>
		<div :class="[{ active: success }, 's-landing-header__overlay']">
			<div class="">
				<div class="icon icon-xl mb-30">
					<div class="svg-constrain">
						<svg
							width="80"
							height="80"
							viewBox="0 0 80 80"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M52.2107 12.5783C46.3015 9.94531 39.6994 9.29302 33.3892 10.7187C27.079 12.1444 21.3987 15.5718 17.1955 20.4895C12.9923 25.4073 10.4913 31.552 10.0657 38.0072C9.64003 44.4625 11.3125 50.8824 14.8335 56.3095C18.3546 61.7366 23.5357 65.8801 29.6041 68.122C35.6724 70.364 42.3029 70.5843 48.5067 68.75C54.7105 66.9157 60.1552 63.1252 64.0287 57.9438C67.9022 52.7623 69.997 46.4676 70.0007 39.9983V36.9335C70.0007 35.0926 71.4931 33.6002 73.334 33.6002C75.1749 33.6002 76.6673 35.0926 76.6673 36.9335V40.0002C76.6628 47.9071 74.1025 55.6026 69.3682 61.9355C64.6339 68.2683 57.9794 72.9012 50.397 75.1431C42.8146 77.3849 34.7106 77.1157 27.2937 74.3756C19.8768 71.6354 13.5444 66.5711 9.24084 59.938C4.93729 53.3049 2.89322 45.4583 3.41347 37.5686C3.93371 29.6788 6.99041 22.1686 12.1277 16.158C17.2649 10.1474 24.2075 5.95851 31.92 4.21598C39.6325 2.47344 47.7016 3.27068 54.924 6.48878C56.6056 7.23804 57.3614 9.20863 56.6121 10.8902C55.8628 12.5718 53.8922 13.3276 52.2107 12.5783Z"
								fill="#FA1855"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M75.6905 10.9751C76.9929 12.2762 76.9939 14.3868 75.6928 15.6892L42.3595 49.0558C41.7345 49.6815 40.8865 50.0331 40.0021 50.0333C39.1178 50.0336 38.2696 49.6824 37.6443 49.057L27.6443 39.057C26.3425 37.7553 26.3425 35.6447 27.6443 34.343C28.946 33.0412 31.0566 33.0412 32.3583 34.343L40.0001 41.9848L70.9764 10.9775C72.2775 9.67509 74.3881 9.67404 75.6905 10.9751Z"
								fill="#FA1855"
							/>
						</svg>
					</div>
				</div>
				<h2 class="h1">
					Thank you!
				</h2>
				<p>
					We'll get back to you as soon as possible
				</p>
				<br />
				<a :href="rootUrl + '/projects'">
					View our recent projects
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	components: {},
	data: function() {
		return {
			rootUrl: document.getElementsByTagName("body")[0].dataset.url,
			loading: false,
			success: false,
			error: false,
			form: {
				firstName: "",
				lastName: "",
				organization: "",
				phone: "",
				email: "",
				googleGrants: "",
				googleGrantsOptions: [
					{
						title: "No, this is the first time"
					},
					{
						title: "Yes, but we have been unsuccessful"
					},
					{
						title: "Yes, we have Google grant funding"
					}
				]
			},
			errors: {
				firstName: 0,
				lastName: 0,
				email: 0,
				googleGrants: 0
			}
		};
	},
	methods: {
		onSubmit: function() {
			this.loading = true;
			var that = this;
			var data = {
				firstName: this.form.firstName,
				lastName: this.form.lastName,
				organization: this.form.organization,
				email: this.form.email,
				phone: this.form.phone,
				googleGrants: this.form.googleGrants
			};
			var url = this.rootUrl;
			url = url + "/wp-json/form/v1/2";
			var result = this.validate();
			if (result) {
				axios
					.get(url, {
						params: data
					})
					.then(function(res) {
						that.loading = false;
						if (res.data.status === 201) {
							that.success = true;
						} else {
							that.error = true;
						}
					})
					.catch(function(err) {});
			} else {
				this.loading = false;
			}
		},
		validate: function() {
			var success = true;
			for (var prop in this.form) {
				if (this.form.hasOwnProperty(prop)) {
					if (!this.form[prop]) {
						if (prop in this.errors) {
							success = false;
							this.errors[prop] = 1;
						}
					} else {
						if (prop in this.errors) {
							if (prop === "email") {
								if (!this.isEmail(this.form[prop])) {
									this.errors[prop] = 2;
									success = false;
								} else {
									this.errors[prop] = 0;
								}
							} else {
								this.errors[prop] = 0;
							}
						}
					}
				}
			}
			return success;
		},
		isEmail: function(email) {
			// We don't need any fancy email regex here.
			var result = email.indexOf("@");
			if (result === -1) {
				return false;
			} else {
				return true;
			}
		}
	},
	created: function() {},
	mounted: function() {}
};
</script>
