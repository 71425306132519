var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "s-slider__container" },
      [
        _vm._l(_vm.sliders, function (slider) {
          return !_vm.carousel
            ? _c("div", { staticClass: "s-slider__frame__outer" }, [
                _c("div", { staticClass: "s-slider__frame" }, [
                  _c("div", { staticClass: "s-slider__frame__inner" }, [
                    _c("img", { attrs: { src: slider.url } }),
                  ]),
                ]),
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _vm.carousel
          ? _c(
              "div",
              {
                class: [
                  "s-slider__button left icon-container",
                  { disabled: _vm.current === 0 },
                ],
                on: { click: _vm.goPrev },
              },
              [
                _c("span", { staticClass: "icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "20",
                        viewBox: "0 0 48 48",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M15.809 33.6335C16.4323 34.2568 17.2776 34.6069 18.159 34.6069C19.0404 34.6069 19.8857 34.2568 20.509 33.6335C21.1323 33.0103 21.4824 32.165 21.4824 31.2835C21.4824 30.4021 21.1323 29.5568 20.509 28.9335L12.289 20.6135L33.989 20.6135C34.8276 20.5485 35.6108 20.1695 36.1821 19.5521C36.7534 18.9348 37.0707 18.1247 37.0707 17.2835C37.0707 16.4424 36.7534 15.6323 36.1821 15.0149C35.6108 14.3976 34.8276 14.0186 33.989 13.9535L12.289 13.9535L20.509 5.73354C20.8176 5.42493 21.0624 5.05856 21.2294 4.65535C21.3964 4.25213 21.4824 3.81997 21.4824 3.38354C21.4824 2.9471 21.3964 2.51494 21.2294 2.11173C21.0624 1.70851 20.8176 1.34214 20.509 1.03354C20.2004 0.724931 19.834 0.480133 19.4308 0.313116C19.0276 0.146099 18.5954 0.0601367 18.159 0.0601367C17.7226 0.0601367 17.2904 0.146099 16.8872 0.313116C16.484 0.480132 16.1176 0.72493 15.809 1.03354L1.909 15.0335C1.59758 15.3406 1.35028 15.7064 1.18149 16.1098C1.0127 16.5133 0.925783 16.9462 0.925783 17.3835C0.925783 17.8208 1.0127 18.2538 1.18149 18.6572C1.35028 19.0607 1.59757 19.4265 1.909 19.7335L15.809 33.6335Z",
                          fill: _vm.color,
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.carousel
          ? _c(
              "div",
              {
                class: [
                  "s-slider__button right icon-container",
                  { disabled: _vm.current === _vm.length - 1 },
                ],
                on: { click: _vm.goNext },
              },
              [
                _c("span", { staticClass: "icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "20",
                        viewBox: "0 0 48 48",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M27.191 8.36647C26.5677 7.74321 25.7224 7.39307 24.841 7.39307C23.9596 7.39307 23.1143 7.74321 22.491 8.36647C21.8677 8.98973 21.5176 9.83505 21.5176 10.7165C21.5176 11.5979 21.8677 12.4432 22.491 13.0665L30.711 21.3865H9.01101C8.17241 21.4515 7.38923 21.8306 6.81792 22.4479C6.24662 23.0652 5.92927 23.8754 5.92927 24.7165C5.92927 25.5576 6.24662 26.3677 6.81792 26.9851C7.38923 27.6024 8.17241 27.9814 9.01101 28.0465H30.711L22.491 36.2665C22.1824 36.5751 21.9376 36.9414 21.7706 37.3447C21.6036 37.7479 21.5176 38.18 21.5176 38.6165C21.5176 39.0529 21.6036 39.4851 21.7706 39.8883C21.9376 40.2915 22.1824 40.6579 22.491 40.9665C22.7996 41.2751 23.166 41.5199 23.5692 41.6869C23.9724 41.8539 24.4046 41.9399 24.841 41.9399C25.2774 41.9399 25.7096 41.8539 26.1128 41.6869C26.516 41.5199 26.8824 41.2751 27.191 40.9665L41.091 26.9665C41.4024 26.6594 41.6497 26.2936 41.8185 25.8902C41.9873 25.4867 42.0742 25.0538 42.0742 24.6165C42.0742 24.1792 41.9873 23.7462 41.8185 23.3428C41.6497 22.9393 41.4024 22.5735 41.091 22.2665L27.191 8.36647Z",
                          fill: _vm.color,
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.carousel
          ? _c(
              "carousel",
              {
                ref: "carousel",
                attrs: { "per-page": 1, "pagination-enabled": false },
                on: { pageChange: _vm.onPageChange },
              },
              _vm._l(_vm.sliders, function (slider) {
                return _c("slide", [
                  _c("div", { staticClass: "s-slider__frame__outer" }, [
                    _c("div", { staticClass: "s-slider__frame" }, [
                      _c("div", { staticClass: "s-slider__frame__inner" }, [
                        _c("img", { attrs: { src: slider.url } }),
                      ]),
                    ]),
                  ]),
                ])
              }),
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }