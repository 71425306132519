var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-0 mt-sm-50 mt-md-100" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-10 offset-sm-1 col-md-6 offset-md-2" }, [
        _c("div", { class: [{ active: _vm.active }, "s-method__circle"] }, [
          !_vm.isMobile
            ? _c(
                "svg",
                {
                  staticClass: "circle-svg",
                  attrs: {
                    width: "556",
                    height: "556",
                    viewBox: "0 0 556 556",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    staticClass: "id6",
                    attrs: {
                      d: "M474.601 81.3055C422.175 28.88 352.459 0 278.419 0C204.288 0 134.663 28.88 82.1454 81.3055C29.8118 133.823 0.839844 203.448 0.839844 277.579C0.839844 351.711 29.7199 421.52 82.1454 473.853C134.663 526.187 204.288 555.159 278.419 555.159C352.551 555.159 422.175 526.279 474.693 473.853C527.026 421.336 555.999 351.711 555.999 277.579C555.999 203.448 526.935 133.639 474.601 81.3055ZM428.982 428.142C388.697 468.335 335.26 490.501 278.419 490.501C221.579 490.501 168.142 468.335 127.857 428.142C87.6639 387.949 65.498 334.42 65.498 277.579C65.498 220.739 87.6639 167.21 127.857 127.017C168.142 86.824 221.579 64.6581 278.419 64.6581C335.26 64.6581 388.697 86.824 428.982 127.017C469.175 167.21 491.34 220.739 491.34 277.579C491.34 334.42 469.083 387.949 428.982 428.142Z",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(6)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(6)
                      },
                      click: function ($event) {
                        return _vm.onClick(6)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id1",
                    attrs: {
                      d: "M444.994 168.681C447.201 167.394 447.845 164.543 446.374 162.427C428.714 136.03 407.1 115.888 378.588 99.7924C347.041 81.8574 312.551 73.3037 278.612 73.3037C212.114 73.3037 147.364 106.139 108.643 165.094C107.263 167.21 107.907 170.061 110.114 171.349L181.487 211.909C183.418 213.013 185.81 212.461 187.189 210.714C207.883 182.754 240.994 164.543 278.336 164.543C315.126 164.543 347.777 182.018 368.471 209.242C369.851 210.99 372.242 211.45 374.174 210.346L444.994 168.681Z",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(1)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(1)
                      },
                      click: function ($event) {
                        return _vm.onClick(1)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id3",
                    attrs: {
                      d: "M111.86 386.569C109.652 387.857 109.009 390.708 110.48 392.824C128.139 419.22 149.661 439.087 178.174 455.183C209.813 473.21 244.303 481.763 278.242 481.763C344.464 481.763 408.846 449.296 447.567 390.892C448.947 388.777 448.303 385.926 446.096 384.638L376.287 343.985C374.355 342.882 371.872 343.341 370.492 345.181C349.89 373.969 316.319 392.732 278.334 392.732C240.9 392.732 207.789 374.521 187.095 346.561C185.807 344.721 183.324 344.261 181.393 345.365L111.86 386.569Z",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(3)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(3)
                      },
                      click: function ($event) {
                        return _vm.onClick(3)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id2",
                    attrs: {
                      d: "M177.81 331.751C171.464 319.611 167.325 306.274 165.669 292.662C164.014 278.774 164.842 264.61 168.153 251.09C169.992 243.732 172.567 236.558 175.787 229.752C176.522 228.28 177.258 226.808 177.994 225.337C179.098 223.221 178.362 220.646 176.338 219.45L140.008 198.848L105.334 179.073C103.219 177.878 100.459 178.614 99.3556 180.821C95.2167 188.455 91.5378 196.365 88.4106 204.367C81.1446 222.761 76.5459 242.26 74.8903 262.035C73.3268 281.441 74.7064 301.032 78.9372 320.071C81.3286 331.016 84.7316 341.777 88.8705 352.17C92.2736 360.723 96.3204 369.001 100.827 377.095C100.827 377.095 100.827 377.187 100.919 377.187C102.115 379.302 104.874 380.038 106.989 378.842L176.338 337.822C178.362 336.626 179.098 334.051 177.994 332.027C177.902 331.843 177.81 331.843 177.81 331.751Z",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(2)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(2)
                      },
                      click: function ($event) {
                        return _vm.onClick(2)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id5",
                    attrs: {
                      d: "M380.156 228.097C395.975 260.196 395.7 298.734 379.604 330.557C378.592 332.58 379.328 335.156 381.259 336.351L514.991 414.254C517.106 415.45 519.865 414.714 521.061 412.598C557.667 346.928 565.761 267.186 543.503 195.262C537.616 176.316 529.706 158.013 519.865 140.721C518.67 138.606 515.91 137.87 513.795 139.066L379.512 217.98C377.489 219.176 376.753 221.751 377.856 223.866C378.776 225.338 379.696 227.086 380.156 228.097Z",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(5)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(5)
                      },
                      click: function ($event) {
                        return _vm.onClick(5)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "id4",
                    attrs: { cx: "278.398", cy: "278.776", r: "103.437" },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(4)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(4)
                      },
                      click: function ($event) {
                        return _vm.onClick(4)
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobile
            ? _c(
                "svg",
                {
                  staticClass: "circle-svg mobile",
                  attrs: {
                    id: "Layer_1",
                    "data-name": "Layer 1",
                    width: "600",
                    height: "600",
                    viewBox: "0 0 600 600",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "human_centred",
                        "data-name": "human centred",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "id4",
                        attrs: {
                          d: "M374.74869,224.30768a107.05516,107.05516,0,1,0,31.34,75.68365A106.44058,106.44058,0,0,0,374.74869,224.30768Z",
                        },
                        on: {
                          mouseover: function ($event) {
                            return _vm.onClick(4)
                          },
                          mouseleave: function ($event) {
                            return _vm.onMouseLeave(4)
                          },
                          click: function ($event) {
                            return _vm.onClick(4)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M264.91852,293.81692V280.06474h4.20985V285.023h4.397v-4.95825h4.20985v13.75217h-4.20985v-5.14537h-4.397v5.14537Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M286.15483,291.94587a3.07835,3.07835,0,0,1-3.18077,2.15169,3.1165,3.1165,0,0,1-3.27432-3.36788v-7.39061H283.816v5.98733c0,.93552.3742,1.40328,1.02908,1.40328.74841,0,1.21617-.56132,1.21617-1.77748v-5.61313h4.11629v10.47785H286.529Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M305.89433,283.05842a3.07139,3.07139,0,0,1,3.27432,3.36788v7.29705h-4.20985v-5.89377c0-.842-.18712-1.40328-1.02908-1.40328-.65488,0-1.21617.46776-1.21617,1.77748v5.51957h-4.11629v-5.89377c0-.842-.28064-1.40328-1.02908-1.40328-.65488,0-1.21617.46776-1.21617,1.77748v5.51957h-4.11629V283.33907h3.64853l.46776,1.9646a3.1224,3.1224,0,0,1,3.18077-2.24524,2.947,2.947,0,0,1,3.08721,2.24524A3.34667,3.34667,0,0,1,305.89433,283.05842Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M320.39491,290.35548a.591.591,0,0,0,.65488.65488,1.32964,1.32964,0,0,0,.56132-.18712l.09356,2.61945a3.87545,3.87545,0,0,1-2.15169.56132,3.01274,3.01274,0,0,1-2.3388-.93552,1.50778,1.50778,0,0,1-.3742-.65488,3.24609,3.24609,0,0,1-2.99368,1.5904c-2.05816,0-3.36788-1.12264-3.36788-3.08721,0-2.05816,1.871-3.18077,4.584-3.18077a6.19433,6.19433,0,0,1,1.40328.09356v-1.02908c0-.56132-.28064-.842-.74841-.842-.56132,0-.93552.3742-.842,1.21617l-3.74208-.46776c.18712-2.15169,1.871-3.555,4.77116-3.555,3.27432,0,4.67761,1.40328,4.67761,3.64853v3.555Zm-4.02273-.65488a5.27455,5.27455,0,0,0-.65488-.09356c-.93552,0-1.40328.3742-1.40328.93552a.86381.86381,0,0,0,.93552.842,1.05787,1.05787,0,0,0,1.12264-1.21617Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M326.94356,285.30367a3.23308,3.23308,0,0,1,3.27432-2.24524,3.10033,3.10033,0,0,1,3.27432,3.46144v7.29705h-4.11629v-5.89377c0-.842-.28064-1.40328-1.02908-1.40328s-1.30972.46776-1.30972,1.77748v5.51957h-4.11629V283.33907h3.64853Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M266.97665,304.48185c2.90012,0,5.05181,1.49684,5.70668,3.83564l-3.83564,1.5904a1.959,1.959,0,0,0-1.9646-1.871c-1.40328,0-2.43236,1.12264-2.43236,3.555s1.02908,3.64853,2.43236,3.64853c1.21617,0,1.77748-.842,1.9646-1.9646l3.83564,1.5904c-.65488,2.3388-2.80656,3.83564-5.70668,3.83564-4.20984,0-6.73576-2.713-6.73576-7.11C260.14735,307.19486,262.7668,304.48185,266.97665,304.48185Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M280.1675,314.58547l3.27432,1.21617a4.79243,4.79243,0,0,1-4.77116,2.99368,5.03459,5.03459,0,0,1-5.426-5.33248c0-3.36788,2.05816-5.61313,5.51957-5.61313a4.46083,4.46083,0,0,1,4.77116,4.77116,7.221,7.221,0,0,1-.18712,1.40328h-6.17445c0,1.12264.65488,1.77748,1.49684,1.77748C279.51262,315.7081,279.98038,315.42743,280.1675,314.58547Zm-.3742-2.3388v-.46776a1.05834,1.05834,0,0,0-1.02908-1.12264,1.43166,1.43166,0,0,0-1.40328,1.5904Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M289.05495,310.00142a3.23308,3.23308,0,0,1,3.27432-2.24525,3.10033,3.10033,0,0,1,3.27432,3.46144v7.29705h-4.11629V312.6209c0-.842-.28064-1.40328-1.02908-1.40328s-1.30972.56132-1.30972,1.77748v5.51957h-4.11629V308.03682h3.64853Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M305.14592,317.76623a4.72059,4.72059,0,0,1-3.08721,1.02908c-2.3388,0-4.02273-1.12264-4.02273-3.83564v-3.9292h-1.21617v-2.99368h1.30972v-2.24522l4.11629-.46776v2.713h2.52592v2.99368h-2.6195v3.27432c0,.74841.3742,1.12264,1.02908,1.12264a2.48824,2.48824,0,0,0,1.49684-.56132Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M314.1269,312.05958a3.56215,3.56215,0,0,0-1.49684-.28064,1.93656,1.93656,0,0,0-2.05816,2.15169v4.584h-4.11629V308.03682h3.64853l.46776,2.24524a2.98565,2.98565,0,0,1,3.74208-2.43236Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M321.79819,314.58547l3.27432,1.21617a4.79243,4.79243,0,0,1-4.77116,2.99368,5.03459,5.03459,0,0,1-5.426-5.33248c0-3.36788,2.05816-5.61313,5.51957-5.61313a4.46083,4.46083,0,0,1,4.77116,4.77116,7.221,7.221,0,0,1-.18712,1.40328h-6.17445c0,1.12264.65488,1.77748,1.49684,1.77748A1.57782,1.57782,0,0,0,321.79819,314.58547Zm-.46776-2.3388v-.46776a1.05834,1.05834,0,0,0-1.02908-1.12264,1.43166,1.43166,0,0,0-1.40328,1.5904Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M333.39865,316.64363a2.96743,2.96743,0,0,1-3.08721,2.15169c-2.3388,0-4.20985-1.9646-4.20985-5.23892,0-3.74208,1.871-5.70668,4.397-5.70668a2.71971,2.71971,0,0,1,2.80656,1.5904v-5.70668h4.11629V318.6082h-3.64853Zm-.09356-4.02276c0-1.21617-.65488-1.871-1.40328-1.871-1.02908,0-1.5904.93552-1.5904,2.61945,0,1.40328.56132,2.24524,1.49684,2.24524.842,0,1.49684-.74841,1.49684-2.05816Z",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("g", { attrs: { id: "engage" } }, [
                    _c("path", {
                      staticClass: "id3",
                      attrs: {
                        d: "M231.05676,201.38627a4.39507,4.39507,0,0,1-1.21617,5.8003,115.098,115.098,0,0,0,1.40328,186.54293,4.535,4.535,0,0,1,1.21617,5.89383l-47.97349,81.381a4.78932,4.78932,0,0,1-6.75449,1.581A218.71613,218.71613,0,0,1,108.3632,191.90955c17.37258-30.75994,38.8054-53.97952,67.292-73.0362a4.66577,4.66577,0,0,1,6.74507,1.48756",
                      },
                      on: {
                        mouseover: function ($event) {
                          return _vm.onClick(3)
                        },
                        mouseleave: function ($event) {
                          return _vm.onMouseLeave(3)
                        },
                        click: function ($event) {
                          return _vm.onClick(3)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M105.93477,305.65124H95.7376V291.89907h10.19717v3.46144H99.76033V297.138h5.426v3.08721h-5.426v1.871h6.17445Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M111.9221,297.138a3.23308,3.23308,0,0,1,3.27432-2.24524,3.10033,3.10033,0,0,1,3.27432,3.46144v7.29705h-4.11629v-5.89377c0-.842-.28064-1.40328-1.02908-1.40328s-1.30972.46776-1.30972,1.77748v5.51957h-4.11629V295.17339h3.64853Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M124.64519,309.95464c-2.43236,0-5.23892-.56132-5.23892-2.43236,0-1.02908.93552-1.68393,2.24524-1.871a2.19224,2.19224,0,0,1-1.68393-2.05816,2.47419,2.47419,0,0,1,2.05816-2.24525,3.12744,3.12744,0,0,1-2.05816-2.90012c0-2.3388,2.05816-3.555,5.05181-3.555a7.82242,7.82242,0,0,1,2.713.46776,2.99167,2.99167,0,0,0,.46776-1.68393l3.18077,1.02908a2.38073,2.38073,0,0,1-1.9646,1.871,2.95363,2.95363,0,0,1,.65488,1.77748c0,2.43236-2.24524,3.46144-5.05181,3.46144a7.56135,7.56135,0,0,1-1.49684-.09356.62153.62153,0,0,0-.3742.46776c0,.28064.18712.3742.56132.3742h2.713c3.18077,0,4.67761,1.12264,4.67761,3.27432C131.10028,308.832,127.91952,309.95464,124.64519,309.95464Zm.18712-2.3388c1.68393,0,2.713-.28064,2.713-.842,0-.46776-.46776-.56132-1.02908-.56132h-2.99368c-.46776,0-.842.18712-.842.56132C122.68059,307.33517,123.33547,307.61584,124.83231,307.61584Zm.28064-7.95193c.65488,0,1.02908-.46776,1.02908-1.21617,0-.842-.3742-1.21617-1.02908-1.21617s-1.02908.46776-1.02908,1.21617S124.45808,299.66391,125.113,299.66391Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M141.48457,302.1898a.591.591,0,0,0,.65488.65488,1.32964,1.32964,0,0,0,.56132-.18712l.09356,2.61945a3.87545,3.87545,0,0,1-2.15169.56132,3.01275,3.01275,0,0,1-2.3388-.93552,1.50778,1.50778,0,0,1-.3742-.65488,3.24609,3.24609,0,0,1-2.99368,1.5904c-2.05816,0-3.36788-1.12264-3.36788-3.08721,0-2.05816,1.871-3.18077,4.584-3.18077a6.19433,6.19433,0,0,1,1.40328.09356v-1.02908c0-.56132-.28064-.842-.74841-.842-.56132,0-.93552.3742-.842,1.21617l-3.74208-.46776c.18712-2.15169,1.871-3.555,4.77116-3.555,3.27432,0,4.67761,1.40328,4.67761,3.64853v3.555Zm-4.02273-.65485a5.27456,5.27456,0,0,0-.65488-.09356c-.93552,0-1.40328.3742-1.40328.93552a.86381.86381,0,0,0,.93552.842,1.05787,1.05787,0,0,0,1.12264-1.21617Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M148.22034,309.95464c-2.43236,0-5.23892-.56132-5.23892-2.43236,0-1.02908.93552-1.68393,2.24525-1.871a2.19224,2.19224,0,0,1-1.68393-2.05816,2.47419,2.47419,0,0,1,2.05816-2.24525,3.12744,3.12744,0,0,1-2.05816-2.90012c0-2.3388,2.05816-3.555,5.05181-3.555a7.82242,7.82242,0,0,1,2.713.46776,2.99167,2.99167,0,0,0,.46776-1.68393l3.18077,1.02908a2.38073,2.38073,0,0,1-1.9646,1.871,2.95363,2.95363,0,0,1,.65488,1.77748c0,2.43236-2.24525,3.46144-5.05181,3.46144a7.56135,7.56135,0,0,1-1.49684-.09356.62153.62153,0,0,0-.3742.46776c0,.28064.18712.3742.56132.3742h2.713c3.18077,0,4.67761,1.12264,4.67761,3.27432C154.67543,308.832,151.49466,309.95464,148.22034,309.95464Zm.18709-2.3388c1.68393,0,2.713-.28064,2.713-.842,0-.46776-.46776-.56132-1.02908-.56132h-2.99368c-.46776,0-.842.18712-.842.56132C146.25574,307.33517,146.91058,307.61584,148.40742,307.61584Zm.18712-7.95193c.65488,0,1.02908-.46776,1.02908-1.21617,0-.842-.3742-1.21617-1.02908-1.21617s-1.02908.46776-1.02908,1.21617C147.659,299.19615,147.93966,299.66391,148.59454,299.66391Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M161.78539,301.722l3.27432,1.21617a4.79243,4.79243,0,0,1-4.77116,2.99368,5.03459,5.03459,0,0,1-5.426-5.33248c0-3.36788,2.05816-5.61313,5.51957-5.61313a4.46083,4.46083,0,0,1,4.77116,4.77116,7.221,7.221,0,0,1-.18712,1.40328h-6.17445c0,1.12264.65488,1.77748,1.49684,1.77748C161.13052,302.84468,161.59828,302.564,161.78539,301.722Zm-.37423-2.3388v-.46776a1.05834,1.05834,0,0,0-1.02908-1.12264,1.43166,1.43166,0,0,0-1.40328,1.5904Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("g", { attrs: { id: "assets" } }, [
                    _c("path", {
                      staticClass: "id2",
                      attrs: {
                        d: "M406.18633,113.40994l-5.94054,10.44045L384.95,150.72789l-1.96457,3.46138-2.90015,5.09854-17.4942,30.85358-.03746.05607-3.424,6.04343a4.511,4.511,0,0,1-5.98736,1.684q-2.24522-1.12269-4.49049-2.24516a117.55452,117.55452,0,0,0-21.7041-7.76487,119.03781,119.03781,0,0,0-42.28557-2.52586,116.48078,116.48078,0,0,0-39.75965,12.34883c-.09353,0-.09353,0-.37417.2807a4.4238,4.4238,0,0,1-5.89377-1.684l-3.70468-6.25858-38.01959-64.27973-6.27737-10.59941a4.84516,4.84516,0,0,1,1.78688-6.54865c0-.09364.10289-.09364.10289-.09364a229.45737,229.45737,0,0,1,26.89623-12.90076q4.49052-1.79624,9.0652-3.40531a215.27444,215.27444,0,0,1,25.56781-7.31584,210.94651,210.94651,0,0,1,62.62379-4.36881A219.19214,219.19214,0,0,1,369.411,91.65908q4.78515,1.62785,9.4862,3.48948a226.20091,226.20091,0,0,1,25.40873,11.81564A4.64433,4.64433,0,0,1,406.18633,113.40994Z",
                      },
                      on: {
                        mouseover: function ($event) {
                          return _vm.onClick(2)
                        },
                        mouseleave: function ($event) {
                          return _vm.onMouseLeave(2)
                        },
                        click: function ($event) {
                          return _vm.onClick(2)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M277.36094,141.76663h-4.67761l-.842,2.43236h-4.11629l4.95826-13.75217h4.77116l4.95826,13.75217h-4.3034Zm-3.64853-2.99365h2.713l-1.30973-3.9292h0Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M286.15484,140.92467a1.28826,1.28826,0,0,0,1.40328.93552c.65486,0,1.02907-.18712,1.02907-.65488,0-1.40328-5.51958,0-5.51958-4.02273,0-2.43236,2.1517-3.64853,4.77116-3.64853s4.20985,1.12264,4.58405,3.08721l-3.36787.3742a1.083,1.083,0,0,0-1.21618-.93552c-.56131,0-.93552.28064-.93552.65488,0,1.40328,5.51958-.09356,5.51958,4.02273,0,2.43236-2.05814,3.64853-4.95826,3.64853-2.3388,0-4.20985-.842-4.67761-2.80656Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M296.53913,140.92467a1.28826,1.28826,0,0,0,1.40327.93552c.65486,0,1.02908-.18712,1.02908-.65488,0-1.40328-5.51958,0-5.51958-4.02273,0-2.43236,2.1517-3.64853,4.77116-3.64853s4.20985,1.12264,4.584,3.08721l-3.36788.3742a1.083,1.083,0,0,0-1.21618-.93552c-.56132,0-.93552.28064-.93552.65488,0,1.40328,5.51957-.09356,5.51957,4.02273,0,2.43236-2.05814,3.64853-4.95826,3.64853-2.3388,0-4.20985-.842-4.67761-2.80656Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M310.57194,140.36335l3.27432,1.21617a4.79244,4.79244,0,0,1-4.77116,2.99368,5.0346,5.0346,0,0,1-5.426-5.33248c0-3.36788,2.05814-5.61313,5.51957-5.61313a4.46083,4.46083,0,0,1,4.77116,4.77116,7.22208,7.22208,0,0,1-.1871,1.40328h-6.17445c0,1.12264.65486,1.77748,1.49684,1.77748A1.69366,1.69366,0,0,0,310.57194,140.36335Zm-.46776-2.43236v-.46776a1.05834,1.05834,0,0,0-1.02908-1.12264,1.43166,1.43166,0,0,0-1.40328,1.5904Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M323.01437,143.45058a4.72063,4.72063,0,0,1-3.08722,1.02908c-2.3388,0-4.02274-1.12264-4.02274-3.83564v-3.9292h-1.21618v-2.99368h1.30972V131.4759l4.11629-.46776v2.713h2.5259v2.99368h-2.61943v3.27432c0,.74841.3742,1.12264,1.02908,1.12264a2.48824,2.48824,0,0,0,1.49684-.56132Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M326.85,140.92467a1.28827,1.28827,0,0,0,1.40328.93552c.65486,0,1.02908-.18712,1.02908-.65488,0-1.40328-5.51957,0-5.51957-4.02273,0-2.43236,2.1517-3.64853,4.77116-3.64853s4.20985,1.12264,4.584,3.08721l-3.36788.3742a1.083,1.083,0,0,0-1.21618-.93552c-.56132,0-.93552.28064-.93552.65488,0,1.40328,5.51957-.09356,5.51957,4.02273,0,2.43236-2.05814,3.64853-4.95826,3.64853-2.3388,0-4.20984-.842-4.67761-2.80656Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("g", { attrs: { id: "outreach" } }, [
                    _c("path", {
                      staticClass: "id1",
                      attrs: {
                        d: "M366.80091,201.47991a4.51735,4.51735,0,0,0,1.21617,5.80019,115.07917,115.07917,0,0,1,1.49681,184.3913,4.49246,4.49246,0,0,0-1.12264,5.80019l49.10549,82.33522a4.67775,4.67775,0,0,0,6.75449,1.48756c28.47727-19.04731,50.21877-42.36979,67.582-73.13921a218.8349,218.8349,0,0,0-70.46346-291.26511,4.777,4.777,0,0,0-6.74507,1.58109",
                      },
                      on: {
                        mouseover: function ($event) {
                          return _vm.onClick(1)
                        },
                        mouseleave: function ($event) {
                          return _vm.onMouseLeave(1)
                        },
                        click: function ($event) {
                          return _vm.onClick(1)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M435.76087,299.33644c0,4.39695-2.61945,7.11-6.73576,7.11s-6.82931-2.713-6.82931-7.11,2.61946-7.11,6.82931-7.11C433.14139,292.22649,435.76087,294.9395,435.76087,299.33644Zm-9.16812,0c0,2.24525.842,3.64853,2.43236,3.64853s2.43236-1.40328,2.43236-3.64853-.842-3.64853-2.43236-3.64853S426.59275,297.0912,426.59275,299.33644Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M443.61924,304.29471a3.07836,3.07836,0,0,1-3.18077,2.1517,3.11649,3.11649,0,0,1-3.27432-3.36788v-7.39061h4.11629v5.98733c0,.93552.3742,1.40328,1.02908,1.40328.74841,0,1.21617-.56132,1.21617-1.77748v-5.61313H447.642v10.47783h-3.555Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M457.27786,305.41733a4.72067,4.72067,0,0,1-3.08721,1.02908c-2.3388,0-4.02273-1.12262-4.02273-3.83564v-3.92919h-1.21617v-2.99366h1.30972v-2.24525l4.11629-.46776v2.713h2.52592v2.99366h-2.52592v3.27432c0,.74842.3742,1.12262,1.02908,1.12262a2.48815,2.48815,0,0,0,1.49684-.56132Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M466.16531,299.8042a3.56237,3.56237,0,0,0-1.49684-.28066,1.93656,1.93656,0,0,0-2.05816,2.1517v4.584H458.494V295.68792h3.64853l.46776,2.24525a2.98565,2.98565,0,0,1,3.74208-2.43236Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M473.93012,302.33012l3.27432,1.21618a4.79244,4.79244,0,0,1-4.77116,2.99366,5.03458,5.03458,0,0,1-5.426-5.33247c0-3.36788,2.05816-5.61313,5.51957-5.61313a4.46083,4.46083,0,0,1,4.77116,4.77116,7.221,7.221,0,0,1-.18712,1.40328h-6.17445c0,1.12262.65488,1.77748,1.49684,1.77748A1.69375,1.69375,0,0,0,473.93012,302.33012Zm-.46776-2.43236V299.43a1.05833,1.05833,0,0,0-1.02908-1.12262,1.43165,1.43165,0,0,0-1.40328,1.59038Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M488.15005,302.70432a.591.591,0,0,0,.65488.65486,1.32981,1.32981,0,0,0,.56132-.1871l.09356,2.61946a3.87545,3.87545,0,0,1-2.15169.56132,3.01275,3.01275,0,0,1-2.3388-.93552,1.50773,1.50773,0,0,1-.3742-.65486,3.24608,3.24608,0,0,1-2.99368,1.59038c-2.05816,0-3.36788-1.12262-3.36788-3.08722,0-2.05814,1.871-3.18077,4.584-3.18077a6.19433,6.19433,0,0,1,1.40328.09356v-1.02908c0-.56132-.28064-.842-.74841-.842-.56132,0-.93552.3742-.842,1.21618l-3.74208-.46776c.18712-2.1517,1.871-3.555,4.77116-3.555,3.27432,0,4.67761,1.40328,4.67761,3.64853v3.555Zm-4.02276-.65486a5.27455,5.27455,0,0,0-.65488-.09356c-.93552,0-1.40328.3742-1.40328.93552a.86381.86381,0,0,0,.93552.842,1.05788,1.05788,0,0,0,1.12264-1.21618Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M495.26,295.50081c2.80656,0,4.584,1.49684,4.77116,3.74208l-3.555.842c-.09356-.842-.3742-1.49684-1.12264-1.49684-.93552,0-1.30972,1.02908-1.30972,2.5259,0,1.59038.3742,2.43236,1.40328,2.43236.56132,0,1.02908-.46776,1.12264-1.40328l3.555,1.21618c-.46776,2.05814-2.3388,3.27432-4.67761,3.27432-3.36788,0-5.51957-2.05814-5.51957-5.51957C489.834,297.559,491.98569,295.50081,495.26,295.50081Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M505.45719,297.65252a3.34667,3.34667,0,0,1,3.27432-2.24524,3.10032,3.10032,0,0,1,3.27432,3.46143v7.29707h-4.11629V300.272c0-.842-.28064-1.40328-1.02908-1.40328s-1.30972.46776-1.30972,1.77748v5.51957h-4.11629V291.291h4.11629v6.36155Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("g", { attrs: { id: "governance" } }, [
                    _c("path", {
                      staticClass: "id6",
                      attrs: {
                        d: "M511.77859,88.31A297.54762,297.54762,0,0,0,369.411,8.62221a303.3923,303.3923,0,0,0-140.92692.50522A297.03313,297.03313,0,0,0,88.22141,88.31C31.75336,144.97442.48821,220.09669.48821,300.08375S31.65047,455.1932,88.22141,511.86714C144.88593,568.33529,220.00826,599.591,300.00468,599.591a297.89306,297.89306,0,0,0,211.77391-87.82684c56.57093-56.66435,87.7332-131.78674,87.7332-211.7738A297.48286,297.48286,0,0,0,511.77859,88.31ZM462.56084,462.44349A229.77333,229.77333,0,1,1,529.8435,299.99033,228.36561,228.36561,0,0,1,462.56084,462.44349Z",
                      },
                      on: {
                        mouseover: function ($event) {
                          return _vm.onClick(6)
                        },
                        mouseleave: function ($event) {
                          return _vm.onMouseLeave(6)
                        },
                        click: function ($event) {
                          return _vm.onClick(6)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M40.85663,344.533l7.42676-1.58789.58057,2.7168-1.37012.77734c1.20313.31055,2.1499,1.68652,2.50684,3.35742.71,3.32031-1.16455,6.5625-5.4917,7.48828-4.30713.9209-7.73877-1.25-8.6123-5.33594-.63672-2.97852.37061-5.55078,2.52295-6.70605l2.34863,3.60156a1.99035,1.99035,0,0,0-1.40088,2.4043c.30957,1.44922,1.67334,2.31543,4.20947,1.77344,2.07275-.44336,3.354-1.53809,2.97119-3.3291-.2627-1.22754-1.06152-1.81445-2.00732-1.6123l-.2417.05176.40039,1.87109-2.55615.54688Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M47.48212,336.324c.51465,3.252-1.08838,5.75684-4.564,6.30664-3.45557.54785-5.75439-1.33789-6.26953-4.58984s1.08838-5.75684,4.54346-6.30371C44.66766,331.18634,46.96649,333.07208,47.48212,336.324Zm-7.88623,1.25c.15771.99512,1.11328,1.24023,2.67822.99219,1.58545-.251,2.39795-.77539,2.24023-1.77148s-1.09277-1.24414-2.67822-.99316C40.27118,336.04962,39.43817,336.57794,39.59589,337.574Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M41.19257,325.57208l-6.09473-1.18066-.437-3.90527,10.83545,2.6582.45068,4.02832-9.97949,4.98828L35.487,327.867l5.71-2.25391Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M40.74237,312.84357l.94434-3.38672a4.7868,4.7868,0,0,1,3.28467,4.583c.22461,3.28613-1.66406,5.54-4.9707,5.7666-3.38867.23145-5.74609-1.65039-5.98193-5.10059a4.47444,4.47444,0,0,1,4.479-5.11426,6.08019,6.08019,0,0,1,1.38867.09082l.41846,6.12109a1.55106,1.55106,0,0,0,1.6416-1.63965A1.36872,1.36872,0,0,0,40.74237,312.84357Zm-2.353.59375a1.54258,1.54258,0,0,0-.45459-.00977,1.03572,1.03572,0,0,0-1.03613,1.14355,1.46119,1.46119,0,0,0,1.65771,1.31055Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M37.71405,300.47443a3.61152,3.61152,0,0,0-.26611,1.50977,1.89313,1.89313,0,0,0,2.19629,1.95508l4.60742-.12891.1167,4.1543-10.46924.29492-.10352-3.66113,2.25-.51562a3.00785,3.00785,0,0,1-2.5127-3.69629Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M35.69257,294.77374a3.27175,3.27175,0,0,1-2.18994-3.30957,3.094,3.094,0,0,1,3.4707-3.2373l7.30713.11914-.06787,4.15771-5.90771-.09619c-.88477-.01465-1.40332.22412-1.4165,1.02686-.01221.74121.49268,1.34619,1.78955,1.36768l5.49561.08936-.06738,4.1582-10.47754-.1709.05957-3.64355Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M41.47235,277.03253a.58473.58473,0,0,0,.69531-.57568.74708.74708,0,0,0-.19336-.54785l2.63672.03857a4.05411,4.05411,0,0,1,.46143,2.21387,3.06212,3.06212,0,0,1-1.09033,2.30371,1.84385,1.84385,0,0,1-.66064.35107,3.156,3.156,0,0,1,1.376,3.09473c-.12549,2.0332-1.31592,3.2793-3.2666,3.15918-2.07471-.12793-3.06982-2.04492-2.90381-4.73535a13.993,13.993,0,0,1,.1709-1.43262l-.98584-.061c-.55469-.03418-.87891.21387-.91064.72754-.03418.5542.33447.92773,1.13672.95654l-.72461,3.728c-2.14648-.29736-3.4585-2.08936-3.27734-5.02637.20361-3.30664,1.72363-4.55273,3.92139-4.41748Zm-.92578,3.9834a4.0114,4.0114,0,0,0-.09961.6123c-.05713.92432.2627,1.41846.87891,1.45654a.80291.80291,0,0,0,.87744-.853c.03418-.55469-.23975-1.12842-1.16406-1.18506Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M37.1203,269.98419a3.272,3.272,0,0,1-1.87109-3.5,3.09423,3.09423,0,0,1,3.75781-2.89893l7.26416.80176-.45605,4.1333-5.873-.64795c-.87988-.09717-1.41846.0918-1.50635.88965-.08154.73682.36475,1.38672,1.65381,1.52881l5.46338.603-.45605,4.1333-10.41553-1.14941.3999-3.62158Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M36.68134,256.30841c.44434-2.80518,2.1748-4.26025,4.40186-4.11572l.23926,3.62158c-.86377-.07422-1.53564.08984-1.65771.8623-.14795.93506.76611,1.47559,2.25,1.71094,1.60547.25439,2.48291-.02344,2.63086-.9585.09375-.58936-.2793-1.12744-1.24219-1.36328l1.71875-3.353c1.94141.76563,2.89111,2.7915,2.51465,5.16943a5.1061,5.1061,0,0,1-6.26611,4.57031C37.89667,261.91779,36.17889,259.47882,36.68134,256.30841Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M45.68622,245.1297l1.81055-3.01367a4.78771,4.78771,0,0,1,1.94873,5.291c-.65674,3.22754-3.07666,4.89893-6.32422,4.23828-3.32861-.67725-5.10059-3.11768-4.41113-6.50635a4.4735,4.4735,0,0,1,5.67676-3.73975,6.01357,6.01357,0,0,1,1.31445.45654L44.47821,247.867a1.55042,1.55042,0,0,0,2.01807-1.144A1.3696,1.3696,0,0,0,45.68622,245.1297Zm-2.42627-.05225a1.53761,1.53761,0,0,0-.43555-.13086,1.03636,1.03636,0,0,0-1.30273.82715,1.461,1.461,0,0,0,1.25,1.7041Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M564.39178,259.64288l-7.4834,1.30029-.47559-2.7373,1.39941-.72412c-1.19043-.35693-2.083-1.76855-2.376-3.45166-.582-3.34619,1.417-6.51367,5.77637-7.27148,4.33984-.75439,7.68555,1.54688,8.40137,5.66357.52148,3.00146-.584,5.5332-2.78027,6.60449l-2.208-3.68994a1.99033,1.99033,0,0,0,1.49316-2.34863c-.25391-1.46045-1.583-2.37842-4.13867-1.93408-2.08887.36328-3.41113,1.40771-3.09766,3.2124.21484,1.2373.99121,1.85449,1.94434,1.689l.24316-.04248-.32812-1.88623,2.57617-.44727Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M557.4621,267.61993c-.39062-3.269,1.30664-5.71,4.80078-6.12744,3.47363-.415,5.69824,1.55713,6.08887,4.82617s-1.30664,5.71-4.78027,6.125C560.07733,272.86115,557.85272,270.889,557.4621,267.61993Zm7.92773-.94727c-.12012-1.001-1.06543-1.28174-2.63867-1.09375-1.59375.19043-2.42578.68359-2.30566,1.68457.11914,1.001,1.04395,1.28418,2.6377,1.09375C564.65643,268.16925,565.509,267.67365,565.38983,266.67267Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M563.33221,278.56085l6.0459,1.41211.28906,3.91992L558.93866,280.824l-.29785-4.043,10.16406-4.60693.31738,4.31006-5.793,2.03564Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M563.298,291.327l-1.07129,3.34717a4.78421,4.78421,0,0,1-3.10742-4.70215c-.10059-3.291,1.87109-5.47168,5.18262-5.57227,3.39453-.10352,5.67773,1.86523,5.78223,5.3208a4.47162,4.47162,0,0,1-4.667,4.939,6.00026,6.00026,0,0,1-1.38379-.14307l-.18652-6.12939a1.55045,1.55045,0,0,0-1.70215,1.5752A1.36954,1.36954,0,0,0,563.298,291.327Zm2.373-.50439a1.54211,1.54211,0,0,0,.4541.02734,1.03555,1.03555,0,0,0,1.07813-1.10352,1.46051,1.46051,0,0,0-1.60645-1.37158Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M565.83417,303.82892a3.60034,3.60034,0,0,0,.334-1.49707,1.8944,1.8944,0,0,0-2.1084-2.05176l-4.61035-.0752.06738-4.1582,10.47754.1709-.05957,3.66406-2.27148.416a3.00831,3.00831,0,0,1,2.34668,3.80566Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M567.66229,309.53986a3.27118,3.27118,0,0,1,2.07031,3.38477,3.09331,3.09331,0,0,1-3.582,3.11133l-7.29687-.37891.21582-4.15234,5.89844.30664c.88379.0459,1.41113-.17383,1.45215-.97559.03906-.74023-.44434-1.3623-1.73926-1.42969l-5.4873-.28516.21582-4.15234,10.46191.54395-.18945,3.6377Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M561.21307,327.0799a.584.584,0,0,0-.7168.54785.74949.74949,0,0,0,.17188.55566l-2.63379-.1416a4.05275,4.05275,0,0,1-.375-2.23047,3.06,3.06,0,0,1,1.17871-2.25977,1.847,1.847,0,0,1,.67383-.3252,3.15724,3.15724,0,0,1-1.25586-3.14648c.2041-2.02734,1.44238-3.22656,3.3877-3.03027,2.06738.208,2.98828,2.16211,2.71875,4.84473a14.03541,14.03541,0,0,1-.22656,1.4248l.98242.09863c.55371.05566.8877-.17969.93848-.69141.05566-.55273-.29785-.94043-1.09863-1l.86816-3.69727c2.13379.37988,3.376,2.22168,3.08105,5.14941-.332,3.29688-1.89844,4.48438-4.08984,4.26367Zm1.08008-3.94629a4.16659,4.16659,0,0,0,.123-.60742c.09277-.92187-.208-1.42773-.82227-1.48926a.80278.80278,0,0,0-.91016.81836,1.07348,1.07348,0,0,0,1.11816,1.22949Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M565.29413,334.27911a3.27136,3.27136,0,0,1,1.7334,3.56934,3.09449,3.09449,0,0,1-3.86719,2.75l-7.22656-1.083.61621-4.1123,5.84277.876c.875.13086,1.4209-.03711,1.53906-.83105.11035-.73242-.31055-1.39941-1.59277-1.59082l-5.43457-.81543.61621-4.11133,10.36133,1.55273-.54,3.60352Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M565.216,347.9588c-.55176,2.78613-2.33691,4.17383-4.55762,3.94336l-.09961-3.62793c.86035.10742,1.53809-.03125,1.68945-.79785.18457-.92871-.709-1.50488-2.18262-1.7959-1.59473-.31641-2.48242-.07227-2.666.85645-.11621.585.23633,1.1377,1.18945,1.41016l-1.84668,3.28516c-1.91016-.83984-2.78223-2.90137-2.31445-5.2627a5.10675,5.10675,0,0,1,6.4375-4.32715C564.216,342.30646,565.83905,344.80939,565.216,347.9588Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "text",
                      attrs: {
                        d: "M555.78143,358.78986l-1.92578,2.94043a4.7853,4.7853,0,0,1-1.74121-5.36035c.78125-3.19824,3.26367-4.77441,6.48145-3.98828,3.29883.80566,4.97363,3.3125,4.15332,6.6709a4.47156,4.47156,0,0,1-5.81445,3.51465,6.04233,6.04233,0,0,1-1.2959-.50684l1.45508-5.957a1.54975,1.54975,0,0,0-2.06055,1.06445A1.36862,1.36862,0,0,0,555.78143,358.78986Zm2.4209.14746a1.58214,1.58214,0,0,0,.42969.14648,1.03544,1.03544,0,0,0,1.334-.77539,1.46025,1.46025,0,0,0-1.18262-1.75Z",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "co-created_value",
                        "data-name": "co-created value",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "id5",
                        attrs: {
                          d: "M447.66554,560.5897a296.99473,296.99473,0,0,1-58.83984,25.51c-77.61011,24.01-163.65009,15.28-234.51007-24.22a4.843,4.843,0,0,1-1.79-6.55l8.18994-14.05a3.379,3.379,0,0,1,.27-.58l79.24-136.02a4.59863,4.59863,0,0,1,5.89-1.69,117.62681,117.62681,0,0,0,104.22009.56c1.02991-.56,2.79993-1.49,4.29993-2.33a4.50567,4.50567,0,0,1,5.99,1.68l3.62,6.17.06006.08,85.1499,144.89A4.83407,4.83407,0,0,1,447.66554,560.5897Z",
                        },
                        on: {
                          mouseover: function ($event) {
                            return _vm.onClick(5)
                          },
                          mouseleave: function ($event) {
                            return _vm.onMouseLeave(5)
                          },
                          click: function ($event) {
                            return _vm.onClick(5)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M252.38663,484.96729c2.90012,0,5.05181,1.49684,5.70668,3.83564l-3.83564,1.5904a1.959,1.959,0,0,0-1.9646-1.871c-1.40328,0-2.43236,1.12264-2.43236,3.555s1.02908,3.64853,2.43236,3.64853c1.21617,0,1.77748-.842,1.9646-1.9646l3.83564,1.5904c-.65488,2.3388-2.80656,3.83564-5.70668,3.83564-4.20985,0-6.73576-2.713-6.73576-7.11S248.27035,484.96729,252.38663,484.96729Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M264.26776,499.18719c-3.27432,0-5.51957-1.9646-5.51957-5.51957a5.15689,5.15689,0,0,1,5.51957-5.51957c3.27432,0,5.51957,1.9646,5.51957,5.51957S267.54209,499.18719,264.26776,499.18719Zm0-7.95193c-1.02908,0-1.40328.93552-1.40328,2.52592s.3742,2.52592,1.40328,2.52592,1.40328-.842,1.40328-2.52592S265.29684,491.23526,264.26776,491.23526Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M271.19061,491.79658h5.51957v3.27432h-5.51957Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M284.94279,484.96729c2.90012,0,5.05181,1.49684,5.70668,3.83564l-3.83564,1.5904a1.959,1.959,0,0,0-1.9646-1.871c-1.40328,0-2.43236,1.12264-2.43236,3.555s1.02908,3.64853,2.43236,3.64853c1.21617,0,1.77748-.842,1.9646-1.9646l3.83564,1.5904c-.65488,2.3388-2.80656,3.83564-5.70668,3.83564-4.20985,0-6.73576-2.713-6.73576-7.11S280.73294,484.96729,284.94279,484.96729Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M299.53692,492.545a3.56216,3.56216,0,0,0-1.49684-.28064,1.93656,1.93656,0,0,0-2.05816,2.15169v4.584h-4.11629V488.52226h3.64853l.46776,2.24525a2.98565,2.98565,0,0,1,3.74208-2.43236Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M307.20821,495.07091l3.27432,1.21617a4.79244,4.79244,0,0,1-4.77113,2.99368,5.03459,5.03459,0,0,1-5.426-5.33248c0-3.36788,2.05816-5.61313,5.5196-5.61313a4.46081,4.46081,0,0,1,4.77113,4.77116,7.21881,7.21881,0,0,1-.18712,1.40328h-6.17442c0,1.12264.65488,1.77748,1.49681,1.77748A1.69378,1.69378,0,0,0,307.20821,495.07091Zm-.46776-2.43236v-.46776a1.05835,1.05835,0,0,0-1.02905-1.12264,1.43164,1.43164,0,0,0-1.40328,1.5904Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M321.42808,495.53867a.591.591,0,0,0,.65488.65488,1.32927,1.32927,0,0,0,.56129-.18712l.09353,2.61945a3.87545,3.87545,0,0,1-2.15169.56132,3.01279,3.01279,0,0,1-2.3388-.93552,1.50811,1.50811,0,0,1-.37423-.65488,3.24609,3.24609,0,0,1-2.99368,1.5904c-2.05816,0-3.36785-1.12264-3.36785-3.08721,0-2.05816,1.871-3.18077,4.58408-3.18077a6.19433,6.19433,0,0,1,1.40328.09356V491.9837c0-.56132-.28064-.842-.74841-.842-.56129,0-.93552.3742-.842,1.21617l-3.74208-.46776c.18712-2.15169,1.871-3.555,4.77113-3.555,3.27432,0,4.67761,1.40328,4.67761,3.64853v3.555Zm-4.02273-.74841a5.27455,5.27455,0,0,0-.65488-.09356c-.93552,0-1.40328.3742-1.40328.93552a.86383.86383,0,0,0,.93552.842,1.05788,1.05788,0,0,0,1.12264-1.21617Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M331.25105,498.25167a4.72059,4.72059,0,0,1-3.08721,1.02908c-2.3388,0-4.02273-1.12264-4.02273-3.83564v-3.9292h-1.21617v-2.99368h1.30975V486.277l4.11632-.46776v2.713h2.52592v2.99368H328.351v3.27432c0,.74841.37423,1.12264,1.02905,1.12264a2.48816,2.48816,0,0,0,1.49681-.56132Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M338.82881,495.07091l3.27432,1.21617a4.79244,4.79244,0,0,1-4.77113,2.99368,5.03459,5.03459,0,0,1-5.426-5.33248c0-3.36788,2.05816-5.61313,5.5196-5.61313a4.46081,4.46081,0,0,1,4.77113,4.77116,7.21881,7.21881,0,0,1-.18712,1.40328h-6.17442c0,1.12264.65488,1.77748,1.49681,1.77748A1.69366,1.69366,0,0,0,338.82881,495.07091Zm-.46776-2.43236v-.46776a1.05835,1.05835,0,0,0-1.02905-1.12264,1.43164,1.43164,0,0,0-1.40328,1.5904Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M350.42924,497.03551a2.96745,2.96745,0,0,1-3.08721,2.15169c-2.3388,0-4.20985-1.9646-4.20985-5.23892,0-3.74208,1.871-5.70668,4.397-5.70668a2.71974,2.71974,0,0,1,2.80656,1.5904V484.1253H354.452v14.87478h-3.64856Zm-.09353-4.02273c0-1.21617-.65488-1.871-1.40328-1.871-1.02905,0-1.5904.93552-1.5904,2.61945,0,1.40328.56129,2.24524,1.49681,2.24524.842,0,1.49681-.74841,1.49681-2.05816v-.93549Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M282.604,509.94569h4.397l-4.77116,13.75217h-4.397l-4.77116-13.75217h4.397l2.61945,8.41969h0Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M296.07548,520.23642a.591.591,0,0,0,.65488.65488,1.32964,1.32964,0,0,0,.56132-.18712l.09356,2.61945a3.87545,3.87545,0,0,1-2.15169.56132,3.01274,3.01274,0,0,1-2.3388-.93552,1.50778,1.50778,0,0,1-.3742-.65488,3.24609,3.24609,0,0,1-2.99368,1.5904c-2.05816,0-3.36788-1.12264-3.36788-3.08721,0-2.05816,1.871-3.18077,4.584-3.18077a6.19433,6.19433,0,0,1,1.40328.09356v-1.02908c0-.56132-.28065-.842-.74841-.842-.56132,0-.93552.3742-.842,1.21617l-3.74208-.46776c.18712-2.15169,1.871-3.555,4.77116-3.555,3.27432,0,4.67761,1.40328,4.67761,3.64853v3.555Zm-4.02273-.74841a5.27454,5.27454,0,0,0-.65488-.09356c-.93552,0-1.40328.3742-1.40328.93552a.86381.86381,0,0,0,.93552.842,1.05787,1.05787,0,0,0,1.12264-1.21617Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M303.93388,523.2301a4.59287,4.59287,0,0,1-2.52592.56132,2.90357,2.90357,0,0,1-3.18077-3.08721V508.72955h4.11629v10.94561c0,.65488.28064.842.65488.842a1.24415,1.24415,0,0,0,.74841-.28064Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M311.51158,521.82682a3.07836,3.07836,0,0,1-3.18079,2.15169,3.11648,3.11648,0,0,1-3.27432-3.36788v-7.29705h4.11632v5.98733c0,.93552.37423,1.40328,1.02905,1.40328.74841,0,1.21617-.56132,1.21617-1.77748v-5.61313h4.11632v10.47785h-3.64856Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "text",
                        attrs: {
                          d: "M323.954,519.76866l3.27432,1.21617a4.79244,4.79244,0,0,1-4.77113,2.99368,5.03459,5.03459,0,0,1-5.426-5.33248c0-3.36788,2.05816-5.61313,5.5196-5.61313a4.46081,4.46081,0,0,1,4.77113,4.77116,7.21881,7.21881,0,0,1-.18712,1.40328h-6.17442c0,1.12264.65488,1.77748,1.49681,1.77748A1.69366,1.69366,0,0,0,323.954,519.76866Zm-.46776-2.43233v-.46776a1.05835,1.05835,0,0,0-1.02905-1.12264,1.43164,1.43164,0,0,0-1.40328,1.5904Z",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "circle-svg2 mobile",
              attrs: {
                width: "140",
                height: "325",
                id: "Layer_1",
                viewBox: "0 0 140.92692 325.01583",
              },
            },
            [
              _c("g", { attrs: { id: "objectives" } }, [
                _c("path", {
                  staticClass: "id7",
                  attrs: {
                    d: "M141.3669,167.70221V68.50528c0-37.06936-29.47549-67.75376-66.53765-68.484Q73.7726.00042,72.69471,0q-1.10728,0-2.19308.02238C31.45458.81036.43256,33.19547.43256,72.25028v95.45193a4.45514,4.45514,0,0,0,4.45514,4.45514H136.91175A4.45514,4.45514,0,0,0,141.3669,167.70221Z",
                  },
                  on: {
                    mouseover: function ($event) {
                      return _vm.onClick(8)
                    },
                    mouseleave: function ($event) {
                      return _vm.onMouseLeave(8)
                    },
                    click: function ($event) {
                      return _vm.onClick(8)
                    },
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M33.89869,93.75393c0,4.38388-2.61471,7.12193-6.77248,7.12193s-6.79258-2.738-6.79258-7.12193c0-4.38434,2.63481-7.12239,6.79258-7.12239S33.89869,89.36959,33.89869,93.75393Zm-9.20173,0c0,2.24379.86517,3.60185,2.42925,3.60185s2.42925-1.35806,2.42925-3.60185-.86517-3.6023-2.42925-3.6023S24.697,91.51014,24.697,93.75393Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M39.45426,92.0044a3.068,3.068,0,0,1,3.06694-2.0999c2.53157,0,4.15777,2.141,4.15777,5.26962,0,3.6224-1.83176,5.70175-4.44647,5.70175a3.05548,3.05548,0,0,1-2.9637-1.914l-.55547,1.66731H35.29558V85.76729h4.15868Zm0,3.93166c0,1.27629.57648,1.914,1.37861,1.914,1.07073,0,1.565-.94648,1.565-2.53157,0-1.48231-.57648-2.26434-1.48276-2.26434-.84325,0-1.46084.76148-1.46084,2.03777Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M52.19343,90.15163v10.00432c0,4.219-3.17017,5.28971-6.31934,4.54879l.24667-3.088c1.153.412,1.914.12334,1.914-1.41973V90.15163Zm-2.07934-.76148a2.08306,2.08306,0,1,1,2.326-2.07889A2.11375,2.11375,0,0,1,50.11409,89.39015Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M60.46786,96.73819l3.31361,1.174a4.7875,4.7875,0,0,1-4.79637,2.9637c-3.29351,0-5.413-2.03732-5.413-5.35138,0-3.39675,2.03732-5.62,5.49527-5.62a4.4735,4.4735,0,0,1,4.79637,4.81693,6.00637,6.00637,0,0,1-.18546,1.37907H57.54435a1.55076,1.55076,0,0,0,1.523,1.74953A1.37046,1.37046,0,0,0,60.46786,96.73819Zm-.433-2.38722a1.5447,1.5447,0,0,0,.04111-.45269,1.036,1.036,0,0,0-1.06982-1.11184A1.46114,1.46114,0,0,0,57.58546,94.351Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M70.141,89.9045c2.84037,0,4.54879,1.48231,4.75435,3.70554l-3.54018.80259c-.06212-.86426-.32889-1.50241-1.11184-1.50241-.94648,0-1.3375.98805-1.3375,2.49046,0,1.6262.412,2.44935,1.35852,2.44935.59658,0,1.07073-.45314,1.153-1.44074l3.58129,1.17306c-.45314,2.03823-2.305,3.29351-4.71415,3.29351a5.10705,5.10705,0,0,1-5.49527-5.47517C64.78916,91.98384,66.92971,89.9045,70.141,89.9045Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M83.90979,99.88827a4.82475,4.82475,0,0,1-3.12906.9876c-2.34611,0-4.03443-1.13194-4.03443-3.80786V93.17745H75.491V90.15163H76.7673V87.9284l4.15777-.45269v2.67592h2.53157v3.02583H80.905v3.29305c0,.76194.34991,1.09175,1.00861,1.09175a2.54635,2.54635,0,0,0,1.523-.53537Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M87.22248,89.39015a2.08306,2.08306,0,1,1,2.326-2.07889A2.11375,2.11375,0,0,1,87.22248,89.39015Zm2.07934.76148v10.47756H85.14314V90.15163Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M96.34016,96.07949l1.85276-5.92786h3.93121l-3.849,10.47756H94.2197l-3.849-10.47756h4.32222l1.6061,5.92786Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M109.04,96.73819l3.31361,1.174a4.7875,4.7875,0,0,1-4.79637,2.9637c-3.29351,0-5.413-2.03732-5.413-5.35138,0-3.39675,2.03732-5.62,5.49527-5.62a4.4735,4.4735,0,0,1,4.79637,4.81693,6.00636,6.00636,0,0,1-.18546,1.37907h-6.13388a1.55076,1.55076,0,0,0,1.523,1.74953A1.37046,1.37046,0,0,0,109.04,96.73819Zm-.433-2.38722a1.5447,1.5447,0,0,0,.04111-.45269,1.036,1.036,0,0,0-1.06982-1.11184,1.46114,1.46114,0,0,0-1.42064,1.56453Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M116.5306,97.31467a1.30074,1.30074,0,0,0,1.35852.9675c.6386,0,1.02962-.22657,1.02962-.69981,0-1.42064-5.49619.021-5.49619-3.99287,0-2.42925,2.16157-3.685,4.75435-3.685,2.5736,0,4.24091,1.153,4.59081,3.088l-3.37573.391a1.07256,1.07256,0,0,0-1.17306-.92639c-.57648,0-.92639.26768-.92639.69981,0,1.4412,5.5574-.08222,5.5574,4.03443,0,2.42925-2.03823,3.68453-5.00193,3.68453-2.30591,0-4.21989-.88527-4.71415-2.84037Z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("g", { attrs: { id: "brand" } }, [
                _c("path", {
                  staticClass: "id8",
                  attrs: {
                    d: "M140.92692,185.69787v139.318A232.05347,232.05347,0,0,0,0,325.69875V185.69787a4.45972,4.45972,0,0,1,4.45309-4.46234H136.47383A4.45972,4.45972,0,0,1,140.92692,185.69787Z",
                  },
                  on: {
                    mouseover: function ($event) {
                      return _vm.onClick(7)
                    },
                    mouseleave: function ($event) {
                      return _vm.onMouseLeave(7)
                    },
                    click: function ($event) {
                      return _vm.onClick(7)
                    },
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M51.22455,250.23878A3.10141,3.10141,0,0,1,54.127,253.4286c0,2.16157-1.523,3.80878-4.83748,3.80878H42.37364V243.48686h6.89581c2.73713,0,4.28111,1.4412,4.28111,3.664a2.89424,2.89424,0,0,1-2.326,3.04684Zm-4.77536-1.09129h1.56407c.92639,0,1.42064-.41157,1.42064-1.25528,0-.86472-.45314-1.3174-1.37953-1.3174H46.44919Zm0,5.02294h1.89388c1.02871,0,1.48185-.433,1.48185-1.27629,0-.82361-.49425-1.29685-1.48185-1.29685H46.44919Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M63.14148,250.79425a3.61384,3.61384,0,0,0-1.50286-.3088,1.89407,1.89407,0,0,0-2.01722,2.141v4.61091H55.46271V246.75982h3.66443l.45314,2.26434a3.008,3.008,0,0,1,3.76675-2.40824Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M73.67888,253.77851a.584.584,0,0,0,.61759.6587.75072.75072,0,0,0,.53537-.22566l.12334,2.63481a4.06121,4.06121,0,0,1-2.18258.59658,3.0643,3.0643,0,0,1-2.36712-.94648,1.84137,1.84137,0,0,1-.391-.6386,3.156,3.156,0,0,1-3.00481,1.565c-2.03823,0-3.35563-1.11184-3.35563-3.06785,0-2.07843,1.85277-3.18982,4.54879-3.18982a14.04119,14.04119,0,0,1,1.44165.08222v-.98805c0-.55592-.26768-.86472-.782-.86472-.55638,0-.90629.391-.88527,1.19407l-3.76767-.49425c.16536-2.16111,1.87378-3.58175,4.81739-3.58175,3.31361,0,4.652,1.4412,4.652,3.64387Zm-4.03443-.6788a4.06921,4.06921,0,0,0-.61759-.06212c-.92639,0-1.39963.34991-1.39963.9675a.80286.80286,0,0,0,.90537.82406,1.07352,1.07352,0,0,0,1.11184-1.23518Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M80.18185,248.75648a3.27221,3.27221,0,0,1,3.27341-2.24379,3.09438,3.09438,0,0,1,3.29351,3.4173v7.30739H82.59009v-5.90776c0-.88527-.24667-1.39963-1.04972-1.39963-.74093,0-1.3375.51435-1.3375,1.8112v5.49619H76.04418V246.75982H79.6876Z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "text",
                  attrs: {
                    d: "M95.28905,255.3435A2.976,2.976,0,0,1,92.2212,257.484c-2.36712,0-4.17787-1.9962-4.17787-5.228,0-3.72564,1.83176-5.74331,4.38434-5.74331a2.81625,2.81625,0,0,1,2.84037,1.56453v-5.70175h4.15777v14.8619H95.7623Zm-.021-4.01388c0-1.19407-.61759-1.83176-1.39963-1.83176-1.04972,0-1.56407.92593-1.56407,2.57268,0,1.4412.59658,2.22323,1.48185,2.22323.86426,0,1.48185-.74093,1.48185-2.01722Z",
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.isMobile
            ? _c(
                "svg",
                {
                  staticClass: "circle-svg2",
                  attrs: {
                    width: "323",
                    height: "139",
                    viewBox: "0 0 323 139",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    staticClass: "id7",
                    attrs: {
                      d: "M321.912 0.0585938H182.755C180.363 0.0585938 178.34 1.99006 178.34 4.47338V134.25C178.34 136.641 180.271 138.664 182.755 138.664H322.28C306.185 93.4128 306.001 45.4941 321.912 0.0585938Z",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(7)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(7)
                      },
                      click: function ($event) {
                        return _vm.onClick(7)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "id8",
                    attrs: {
                      d: "M164.819 0.0585938H67.3254C30.9035 0.0585938 0.735797 29.0306 0 65.4525C0 66.1883 0 66.8322 0 67.568C0 68.3038 0 69.0395 0 69.6834C0.735797 108.037 32.651 138.572 71.0044 138.572H164.819C167.21 138.572 169.233 136.641 169.233 134.158V4.3814C169.141 1.99006 167.21 0.0585938 164.819 0.0585938Z",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.onClick(8)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(8)
                      },
                      click: function ($event) {
                        return _vm.onClick(8)
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMobile
            ? _c("div", { class: [{ active: _vm.active }, "circle-text"] }, [
                _c("h3", { staticClass: "id1" }, [
                  _vm._v("\n\t\t\t\t\t\tOutreach\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "id2" }, [
                  _vm._v("\n\t\t\t\t\t\tAssets\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "id3" }, [
                  _vm._v("\n\t\t\t\t\t\tEngage\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "id4" }, [
                  _vm._v("\n\t\t\t\t\t\tHuman Centred\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("h3", { staticClass: "id6" }, [
                  _vm._v("\n\t\t\t\t\t\tGovernance\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "id7" }, [
                  _vm._v("\n\t\t\t\t\t\tBrand\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "id8" }, [
                  _vm._v("\n\t\t\t\t\t\tObjectives\n\t\t\t\t\t"),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 offset-0 col-md-3 offset-md-1" }, [
        _c(
          "div",
          { class: [{ active: _vm.active }, "s-method__content"] },
          [
            _c("transition", { attrs: { name: "slide", mode: "out-in" } }, [
              _c("article", {
                key: _vm.currentItem,
                domProps: { innerHTML: _vm._s(_vm.currentContent) },
              }),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "id5" }, [
      _vm._v("\n\t\t\t\t\t\tCo-Created"),
      _c("br"),
      _vm._v("\n\t\t\t\t\t\tValue\n\t\t\t\t\t"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }