<template>
	<div :class="[{ active: active }, 's-profiles']">
		<div class="container">
			<div class="s-profiles__container">
				<div
					:class="['s-profiles__profile', { blank: person.angle === null }]"
					v-for="(person, index) in people"
					:ref="'person-' + index"
					@click="onClick(person)"
				>
					<div
						class="s-profiles__bounding-box"
						:ref="'person-box-' + index"
					></div>
					<div
						:class="[
							's-profiles__profile__image',
							'anim anim-slideup',
							{ mixblendmode: mixBlendMode }
						]"
					>
						<sprite
							:resized="resized"
							:imageSource="person.avatar"
							divisions="13"
							:currentDivision="person.angle + 1"
						>
						</sprite>
					</div>
					<div class="s-profiles__profile__info">
						<strong>
							{{ person.name }}
						</strong>
						<em class="p-small" v-if="person.role">
							{{ person.role }}
						</em>
					</div>
				</div>
			</div>
		</div>
		<modal
			name="modal-profiles"
			width="100%"
			height="auto"
			:scrollable="true"
			@closed="onModalClose"
			@opened="onModalOpen"
		>
			<div v-if="currentProfile">
				<div class="close">
					<span @click="$modal.hide('modal-profiles')">
						Close
						<span class="icon icon-white-close icon-xs"> </span>
					</span>
				</div>
				<div class="container">
					<h3 v-if="currentProfile.angle">
						{{ currentProfile.name }}
					</h3>
					<h3 v-if="!currentProfile.angle">
						Join Us
					</h3>
					<span class="p-lead" v-if="currentProfile.angle">
						{{ currentProfile.role }}
					</span>
					<article class="mt-30" v-if="!currentProfile.angle">
						<p>
							We'd like to hear from you
						</p>
						<p>
							We're immensely proud of our collaborative culture, trust and
							empowerment and are always on the lookout for talented A-player
							individuals to join our amazing team. Our studio is located in
							South Melbourne surrounded by great coffee and friends. So if you
							are a marketing or digital changemaker, we’d love to hear from
							you. Send your CV with recent work samples to
							jobs@pluralagency.com.au
						</p>
					</article>
					<article class="mt-30" v-html="currentProfile.content"></article>
				</div>
			</div>
		</modal>
		<resize-observer @notify="handleResize" />
	</div>
</template>

<script>
import sprite from "../components/sprite";
import { eventBus } from "../entry";
import vueSlider from "vue-slider-component";
import axios from "axios";

export default {
	components: {
		vueSlider: vueSlider,
		sprite: sprite
	},
	data: function() {
		return {
			hasDeviceOrientation: false,
			lastOrientation: {
				x: 0,
				y: 0
			},
			currentProfile: null,
			resized: false,
			mixBlendMode: true,
			rootUrl: "",
			active: false,
			people: [],
			isModalActive: false,
			lastMousePos: {
				x: 0,
				y: 0
			}
		};
	},
	watch: {},
	computed: {},
	methods: {
		getOffset: function(el) {
			el = el.getBoundingClientRect();
			return {
				top: el.top + document.body.scrollTop,
				left: el.left + document.body.scrollLeft
			};
		},
		onMouseMove: function(e) {
			var diffx = e.clientX - this.lastMousePos.x;
			var diffy = e.clientY - this.lastMousePos.y;
			if (diffx < 2 && diffx > -2 && diffy < 2 && diffy > -2) {
				return;
			}
			var movingHeads = true;
			this.lastMousePos.x = e.clientX;
			this.lastMousePos.y = e.clientY;
			for (var i = 0; i < this.people.length; i++) {
				// Skip over one iteration of this loop if the angle is not defined. Basically skip over the blank profile.
				if (this.people[i].angle === null) {
					continue;
				}
				var ref = this.$refs["person-box-" + i][0];
				var width = ref.offsetWidth;
				var height = ref.offsetHeight;
				var offset = this.getOffset(ref);
				var angle = 0;
				// If mouse is over the element, show the 12th angle
				if (
					e.clientX >= offset.left &&
					e.clientX <= offset.left + width &&
					e.clientY >= offset.top &&
					e.clientY <= offset.top + height
				) {
					angle = 12;
					// else calculate the angle from the element to the mouse
				} else {
					// Get the center of the profile
					var centerx = offset.left + width / 2;
					var centery = offset.top + height / 2;
					var tanx, tany;
					// This gets the point between the center of the profile and the mouse position
					tanx = e.clientX - centerx;
					tany = e.clientY - centery;
					// This will get us the angle made from 0,0 to the above point
					var angle = Math.round(Math.atan2(tanx, tany) / (Math.PI / 6));
					var ff = angle;
					// Convert the angle into 12 different segments
					angle -= 3;
					angle = (12 - angle) % 12;
				}
				this.people[i].angle = angle;
				this.$set(this.people, i, this.people[i]);
			}
		},
		onDeviceMove: function() {
			for (var i = 0; i < this.people.length; i++) {
				// Skip over one iteration of this loop if the angle is not defined. Basically skip over the blank profile.
				if (this.people[i].angle === null) {
					continue;
				}
				var tanx = this.lastOrientation.x;
				var tany = this.lastOrientation.y;
				// This will get us the angle made from 0,0 to the above point
				var angle = Math.round(Math.atan2(tanx, tany) / (Math.PI / 6));
				var ff = angle;
				// Convert the angle into 12 different segments
				angle -= 3;
				angle = (12 - angle) % 12;
				var roundedX = Math.round(this.lastOrientation.x);
				var roundedY = Math.round(this.lastOrientation.y);
				if (
					roundedX >= 0 &&
					roundedX <= 1 &&
					(roundedY >= 0 && roundedY <= 1)
				) {
					this.people[i].angle = 12;
				} else {
					this.people[i].angle = angle;
				}
				this.$set(this.people, i, this.people[i]);
			}
		},
		watchCursor: function() {
			var that = this;
			document.querySelector("body").addEventListener("mousemove", function(e) {
				if (!that.isModalActive) {
					that.onMouseMove(e);
				}
			});
		},
		loadData: function() {
			var path = "/wp-json/wp/v2/pages/151";
			var that = this;
			axios.get(this.rootUrl + path).then(function(res) {
				if (res.data) {
					if (res.data.acf.section_blocks) {
						res.data.acf.section_blocks.map(function(item) {
							if (item.acf_fc_layout === "profiles") {
								item.profiles.map(function(profile) {
									that.people.push({
										name: profile.name,
										avatar:
											"/wp-content/themes/demo/assets/images/sections/profile/" +
											profile.name +
											".jpg", //profile.image.url,
										role: profile.role,
										content: profile.content,
										angle: 12
									});
								});
							}
						});
					}
				}
				that.people.push({
					name: "And you?",
					avatar: null,
					role: null,
					angle: null
				});
			});
		},
		onClick: function(person) {
			if (!this.isModalActive) {
				this.currentProfile = person;
				this.$modal.show("modal-profiles");
			}
		},
		handleResize: function() {
			this.resized = !this.resized;
		},
		updateOrientation: function() {
			if (!this.isModalActive) {
				this.onDeviceMove();
			}
		},
		onDeviceOrientationChange: function(e) {
			this.lastOrientation.x = Math.round(e.gamma) || 0;
			this.lastOrientation.y = Math.round(e.beta) || 0;
		},
		onModalClose: function() {
			this.isModalActive = false;
		},
		onModalOpen: function() {
			this.isModalActive = true;
		}
	},
	mounted: function() {
		var body = document.getElementsByTagName("body")[0];
		if (
			typeof window.getComputedStyle(document.body).mixBlendMode === "undefined"
		) {
			this.mixBlendMode = false;
		}
		this.rootUrl = body.dataset.url;
		this.watchCursor();
		this.loadData();

		if (window.DeviceOrientationEvent && "ontouchstart" in window) {
			this.hasDeviceOrientation = true;
			window.addEventListener(
				"deviceorientation",
				this.onDeviceOrientationChange,
				false
			);
			animationId = setInterval(this.updateOrientation, 50);
		}
	}
};
</script>
