var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [{ active: _vm.active }, "site-menu"] },
    [
      _c("div", { staticClass: "site-menu__primary fullheight-col" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "site-menu__menu" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "site-menu__menu__inner",
                      attrs: { name: "staggered-slideup", tag: "div" },
                      on: {
                        "before-enter": _vm.staggeredBeforeEnter,
                        enter: _vm.staggeredEnter,
                        leave: _vm.staggeredLeave,
                      },
                    },
                    _vm._l(_vm.tempMenuItems, function (item, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          attrs: { "data-index": index, href: item.url },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(item.name) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideleft" } }, [
        _vm.currentPage == "services"
          ? _c(
              "div",
              {
                class: [
                  { active: _vm.currentPage == "services" },
                  "site-menu__page fullheight-col",
                ],
              },
              [
                _c("div", { staticClass: "site-menu__page__inner" }, [
                  _c("div", { staticClass: "vertically-align" }, [
                    _c("div", { staticClass: "site-menu__block-container" }, [
                      _c("a", { staticClass: "block", attrs: { href: "#" } }, [
                        _c("div", { staticClass: "block__img" }, [
                          _c(
                            "div",
                            { staticClass: "object-fit object-fit--contain" },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/wp-content/themes/demo/assets/images/sections/menu/block-1.png",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "p-bold" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tBrand Strategy and Activation\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("a", { staticClass: "block", attrs: { href: "#" } }, [
                        _c("div", { staticClass: "block__img" }, [
                          _c(
                            "div",
                            { staticClass: "object-fit object-fit--contain" },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/wp-content/themes/demo/assets/images/sections/menu/block-2.png",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "p-bold" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tCommunication Strategy and Design\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("a", { staticClass: "block", attrs: { href: "#" } }, [
                        _c("div", { staticClass: "block__img" }, [
                          _c(
                            "div",
                            { staticClass: "object-fit object-fit--contain" },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/wp-content/themes/demo/assets/images/sections/menu/block-3.png",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "p-bold" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tSocial and Behaviour Change Marketing\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("a", { staticClass: "block", attrs: { href: "#" } }, [
                        _c("div", { staticClass: "block__img" }),
                        _vm._v(" "),
                        _c("p", { staticClass: "p-bold" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tDigital Strategy and Transformation\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideleft" } }, [
        _vm.currentPage == "projects"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "touch",
                    rawName: "v-touch:swipe.right",
                    value: _vm.onSwipe,
                    expression: "onSwipe",
                    arg: "swipe",
                    modifiers: { right: true },
                  },
                ],
                class: [
                  { active: _vm.currentPage == "projects" },
                  "site-menu__page fullheight-col",
                ],
              },
              [
                _vm._l(_vm.projects, function (project) {
                  return _c("lazyimg", {
                    attrs: {
                      imageClass: [
                        { active: project.name === _vm.currentProject },
                        "site-menu__cover",
                      ],
                      imageSource: project.image,
                    },
                  })
                }),
                _vm._v(" "),
                _c("div", { staticClass: "site-menu__page__inner" }, [
                  _c("div", { staticClass: "vertically-align" }, [
                    _c("div", { staticClass: "site-menu__block-container" }, [
                      _c(
                        "ul",
                        { staticClass: "blank-list site-menu__list" },
                        _vm._l(_vm.projects, function (project) {
                          return _c("li", [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "touch",
                                    rawName: "v-touch",
                                    value: _vm.onTouch,
                                    expression: "onTouch",
                                  },
                                ],
                                class: [
                                  {
                                    active: _vm.currentProject === project.name,
                                  },
                                  "d-block",
                                ],
                                attrs: { href: project.url },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.onMouseOver(project.name)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(project.name) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }