var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container anim anim-slideup" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 col-lg-7 col-xl-6" }, [
        _c(
          "div",
          { staticClass: "vertically-align" },
          [
            _c(
              "h3",
              {
                staticClass:
                  "h1 anim anim-slideup anim-slideup-50 anim-order-2",
              },
              [_vm._v("\n\t\t\t\t\tOur Services\n\t\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "nav",
              { staticClass: "c-nav-buttons" },
              _vm._l(_vm.items, function (item) {
                return _c(
                  "a",
                  {
                    class: [
                      { active: item.name === _vm.currentItemName },
                      "btn btn-secondary",
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onClick(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t\t"
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.toggle
                ? _c("div", [
                    _vm.currentItem
                      ? _c(
                          "ul",
                          _vm._l(_vm.currentItem.list, function (i, $index) {
                            return _c("li", { key: $index }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(i) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.currentItem
              ? _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-tertiary mb-50 mb-md-0",
                      attrs: { href: _vm.currentItem.href },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tExplore our full services\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-lg-5 col-xl-6 col-mod" }, [
        _vm.currentItem
          ? _c(
              "div",
              { staticClass: "image-container" },
              _vm._l(_vm.items, function (item) {
                return item.image
                  ? _c("lazyimg", {
                      class: [
                        { active: item.name === _vm.currentItemName },
                        item.name,
                      ],
                      attrs: { imageSource: item.image },
                    })
                  : _vm._e()
              }),
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }