var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "s-preview__container" },
      [
        _vm._l(_vm.previews, function (preview, index) {
          return !_vm.carousel
            ? _c("div", { staticClass: "s-preview__frame__outer" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "s-preview__frame anim anim-slideup anim-order-6",
                  },
                  [
                    _vm.header[index] !== undefined
                      ? _c("div", { staticClass: "s-preview__header" }, [
                          _c("img", { attrs: { src: _vm.header[index].url } }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          { offset: _vm.header[index] !== undefined },
                          "s-preview__frame__inner",
                        ],
                      },
                      [_c("img", { attrs: { src: preview.url } })]
                    ),
                  ]
                ),
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _vm.carousel
          ? _c(
              "div",
              {
                class: [
                  "s-preview__button left icon-container",
                  { disabled: _vm.current === 0 },
                ],
                on: { click: _vm.goPrev },
              },
              [
                _c("span", { staticClass: "icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "20",
                        viewBox: "0 0 20 20",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M12.452 4.516c0.446 0.436 0.481 1.043 0 1.576l-3.747 3.908 3.747 3.908c0.481 0.533 0.446 1.141 0 1.574-0.445 0.436-1.197 0.408-1.615 0-0.418-0.406-4.502-4.695-4.502-4.695-0.223-0.217-0.335-0.502-0.335-0.787s0.112-0.57 0.335-0.789c0 0 4.084-4.287 4.502-4.695s1.17-0.436 1.615 0z",
                          fill: _vm.color,
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.carousel
          ? _c(
              "div",
              {
                class: [
                  "s-preview__button right icon-container",
                  { disabled: _vm.current === _vm.length - 1 },
                ],
                on: { click: _vm.goNext },
              },
              [
                _c("span", { staticClass: "icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "20",
                        viewBox: "0 0 20 20",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M9.163 4.516c0.418 0.408 4.502 4.695 4.502 4.695 0.223 0.219 0.335 0.504 0.335 0.789s-0.112 0.57-0.335 0.787c0 0-4.084 4.289-4.502 4.695-0.418 0.408-1.17 0.436-1.615 0-0.446-0.434-0.481-1.041 0-1.574l3.747-3.908-3.747-3.908c-0.481-0.533-0.446-1.141 0-1.576s1.197-0.409 1.615 0z",
                          fill: _vm.color,
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.carousel
          ? _c(
              "carousel",
              {
                ref: "carousel",
                attrs: {
                  "per-page": 1,
                  mouseDrag: false,
                  "pagination-enabled": false,
                },
                on: { pageChange: _vm.onPageChange },
              },
              _vm._l(_vm.previews, function (preview) {
                return _c("slide", [
                  _c("div", { staticClass: "s-preview__frame__outer" }, [
                    _c("div", { staticClass: "s-preview__frame" }, [
                      _vm.header
                        ? _c("div", { staticClass: "s-preview__header" }, [
                            _c("img", { attrs: { src: _vm.header.url } }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [
                            { offset: _vm.header },
                            "s-preview__frame__inner",
                          ],
                        },
                        [_c("img", { attrs: { src: preview.url } })]
                      ),
                    ]),
                  ]),
                ])
              }),
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }