<template>
	<div class="container anim anim-slideup">
		<div class="row">
			<div class="col-md-6 col-lg-7 col-xl-6">
				<div class="vertically-align">
					<h3 class="h1 anim anim-slideup anim-slideup-50 anim-order-2">
						Our Services
					</h3>
					<nav class="c-nav-buttons">
						<a
							v-for="item in items"
							href="#"
							:class="[
								{ active: item.name === currentItemName },
								'btn btn-secondary'
							]"
							@click.prevent="onClick(item)"
						>
							{{ item.name }}
						</a>
					</nav>
					<transition name="fade">
						<div v-if="toggle">
							<ul v-if="currentItem">
								<li v-for="(i, $index) in currentItem.list" :key="$index">
									{{ i }}
								</li>
							</ul>
						</div>
					</transition>
					<div v-if="currentItem">
						<a :href="currentItem.href" class="btn btn-tertiary mb-50 mb-md-0">
							Explore our full services
						</a>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-lg-5 col-xl-6 col-mod">
				<div class="image-container" v-if="currentItem">
					<lazyimg
						v-for="item in items"
						v-if="item.image"
						:class="[{ active: item.name === currentItemName }, item.name]"
						:imageSource="item.image"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import lazyimg from "../components/lazyImg";
export default {
	components: {
		lazyimg: lazyimg
	},
	data: function() {
		return {
			toggle: true,
			items: [
				{
					href: "/services/brand",
					name: "Brand",
					image:
						"/wp-content/themes/demo/assets/images/gifs/svgs/brand@1000.svg",
					list: [
						"Stakeholder workshops",
						"Customer journey mapping",
						"Research and interviews",
						"Brand strategy and identity",
						"Quantitative surveys and NPS",
						"Guidelines and governance"
					]
				},
				{
					href: "/services/campaign",
					name: "Campaign",
					image:
						"/wp-content/themes/demo/assets/images/gifs/svgs/campaign@1000.svg",
					list: [
						"Behaviour change campaigns",
						"Digital engagement strategy",
						"Social marketing and advocacy",
						"Creative communication design",
						"Brand experience and activation",
						"Content and media planning"
					]
				},
				{
					href: "/services/digital",
					name: "Digital",
					image:
						"/wp-content/themes/demo/assets/images/gifs/svgs/digital@1000.svg",
					list: [
						"Digital transformation strategy",
						"System design and integration",
						"User experience design",
						"Web and mobile development",
						"Content and site architecture",
						"Digital marketing and SEO"
					]
				}
			],
			currentItem: null
		};
	},
	computed: {
		currentItemName: function() {
			var name = "";
			if (this.currentItem) {
				name = this.currentItem.name;
			}
			return name;
		}
	},
	watch: {
		currentItem: function() {
			this.toggle = false;
			this.$nextTick(() => {
				this.toggle = true;
			});
		}
	},
	methods: {
		onClick: function(item) {
			this.currentItem = item;
		}
	},
	mounted: function() {
		this.currentItem = this.items[0];
	}
};
</script>
