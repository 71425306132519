var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "root", staticClass: "s-projects__slider" }, [
    _c(
      "div",
      { staticClass: "s-projects__inner" },
      [
        _c(
          "div",
          { staticClass: "s-projects__covers" },
          _vm._l(_vm.projects, function (project, i) {
            return _c("lazyimg", {
              attrs: {
                imageClass: [{ active: _vm.currentIndex === i }],
                imageSource: project.thumbnail.src,
                alt: project.thumbnail.alt,
                srcset: project.thumbnail.srcset,
                sizes: project.thumbnail.sizes,
              },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "slick",
          {
            ref: "slick",
            attrs: { options: _vm.slickOptions },
            on: { afterChange: _vm.handleAfterChange },
          },
          _vm._l(_vm.projects, function (project) {
            return _c(
              "div",
              { staticClass: "s-projects__slide fullheight-col" },
              [
                _c("div", { staticClass: "s-projects__slide fullheight-col" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "vertically-align" }, [
                          _c("p", { staticClass: "fadeout mb-30 breadcrumb" }, [
                            _c("span", { staticClass: "p-bold" }, [
                              _vm._v("Project"),
                            ]),
                            _c("span", [_vm._v(" / ")]),
                            _vm._v(
                              _vm._s(project.company_name) +
                                "\n\t\t\t\t\t\t\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-10 col-lg-8" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link",
                                    attrs: { href: project.permalink },
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "h1 mb-30 mb-lg-50 fadeout",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(project.title) +
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fadeout fadeout-late" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-tertiary btn-tertiary-white",
                                attrs: { href: project.permalink },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t\tRead project\n\t\t\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "nav",
      { staticClass: "s-projects__nav" },
      _vm._l(_vm.projects, function (project, i) {
        return _c("a", {
          key: i,
          class: [{ active: i === _vm.currentIndex }],
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.onNavClick(i)
            },
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }