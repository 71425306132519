var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.items, function (item, index) {
        return _c(
          "div",
          {
            class: [
              { active: index === _vm.currentItemIndex },
              "col-8 offset-2 col-md-4 offset-md-0",
            ],
            on: {
              click: function ($event) {
                _vm.currentItemIndex = index
              },
            },
          },
          [
            _c("div", { staticClass: "s-packages-footer__tab" }, [
              _c("h3", { staticClass: "h2" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(item.price) + "\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "p-small" }, [
                _vm._v("\n\t\t\t\t\t+GST\n\t\t\t\t"),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-10 offset-md-1" },
        [
          _c("h3", { staticClass: "mb-30" }, [
            _vm._v("\n\t\t\t\tWhat we'll cover\n\t\t\t"),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.currentItem
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.currentItem.list, function (l) {
                    return _c("div", { staticClass: "col-md-6" }, [
                      _c("p", { staticClass: "list-item" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" + _vm._s(l) + "\n\t\t\t\t\t\t"
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }