import Vue from "vue";
import { ResizeObserver } from "vue-resize";
import "vue-resize/dist/vue-resize.css";
import VueRouter from "vue-router";
import vmodal from "vue-js-modal";

import Menu from "./sections/menu.vue";
import burgerMenu from "./components/burgerMenu.vue";
import Vue2TouchEvents from "vue2-touch-events";
import planner from "./sections/planner.vue";
import profiles from "./sections/profiles.vue";
import preview from "./sections/preview.vue";
import slider from "./sections/slider.vue";
import solutions from "./sections/solutions.vue";
import method from "./sections/method.vue";
import projects from "./sections/projects.vue";
import packagesFooter from "./sections/packages-footer.vue";
import landingHeader from "./sections/landing-header.vue";
import "./sections/testimonials.js";
import "./sections/scroll-anchors.js";

import intro from "./sections/intro";
import "scroll-behavior-polyfill";
import "bootstrap/js/src/collapse.js";
import "jarallax";
import textWithImage from "./sections/text-with-image-project-page";
import { gsap, ScrollTrigger } from "gsap/all";

import "lazysizes";

Vue.use(Vue2TouchEvents);
Vue.use(vmodal);
Vue.use(VueRouter);
Vue.component("resize-observer", ResizeObserver);
Vue.config.devtools = true;

export const eventBus = new Vue();

// register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

function init() {
  // Planner
  new Vue({
    components: {
      planner: planner,
    },
    render: function(h) {
      return h("div", { class: { "s-planner__container": true } }, [
        h(planner),
      ]);
    },
  }).$mount("#planner");

  // Menu system
  new Vue({
    components: {
      Menu: Menu,
      burgerMenu: burgerMenu,
    },
    render: function(h) {
      return h("div", { class: { "site-header__menu": true } }, [
        h(Menu),
        h(burgerMenu),
      ]);
    },
  }).$mount("#burgermenu");
}

window.sections = window.sections || {};
window.pages = window.pages || {};
// window.sections["intro"] = {
//   init: function() {
//     //console.log('intro init');
//   },
//   finalize: function() {
//     //console.log('intro finalize');
//   },
// };
// window.sections["textWithImage"] = {
//   init: function() {
//     //console.log('intro init');
//   },
//   finalize: function() {
//     //console.log('intro finalize');
//   },
// };
// window.sections["hero_minimal"] = {
//   init: function() {
//     //console.log('intro init');
//   },
//   finalize: function() {
//     //console.log('intro finalize');
//   },
// };
window.sections["profiles"] = {
  init: function() {
    new Vue({
      components: ["profiles"],
      render: function(h) {
        return h("div", { class: { "s-profiles__root": true } }, [h(profiles)]);
      },
    }).$mount(".s-profiles");
  },
};
window.sections["preview"] = {
  init: function() {
    new Vue({
      components: ["preview"],
      render: function(h) {
        return h("div", { class: { "s-preview__root": true } }, [h(preview)]);
      },
    }).$mount(".s-preview__root");
  },
};
window.sections["slider"] = {
  init: function() {
    new Vue({
      components: ["slider"],
      render: function(h) {
        return h("div", { class: { "s-slider__root": true } }, [h(slider)]);
      },
    }).$mount(".s-slider__root");
  },
};
window.sections["solutions"] = {
  init: function() {
    new Vue({
      components: {
        solutions: solutions,
      },
      render: function(h) {
        return h("div", { class: { "s-solutions__root": true } }, [
          h(solutions),
        ]);
      },
    }).$mount(".s-solutions__root");
  },
};
window.sections["method"] = {
  init: function() {
    new Vue({
      components: ["method"],
      render: function(h) {
        return h("div", { class: { "s-method__root": true } }, [h(method)]);
      },
    }).$mount(".s-method__root");
  },
};
window.sections["projects"] = {
  init: function() {
    var data = $(".s-projects .data").text();
    data = JSON.parse(data);
    $(".s-projects .data").remove();
    new Vue({
      components: ["projects"],
      render: function(h) {
        return h("div", { class: { "s-projects__root": true } }, [
          h(projects, { props: { projects: data } }),
        ]);
      },
    }).$mount(".s-projects__root");
  },
};
window.sections["packages_footer"] = {
  init: function() {
    new Vue({
      components: ["packages-footer"],
      render: function(h) {
        return h("div", { class: { "s-packages-footer__tabs": true } }, [
          h(packagesFooter),
        ]);
      },
    }).$mount(".s-packages-footer__tabs");
  },
};
window.sections["landing_header"] = {
  init: function() {
    if(document.querySelectorAll(".s-landing-header__form").length) {
        new Vue({
            components: {name: "landing-header"},
            render: function(h) {
              return h(
                "div",
                {
                  class: {
                    "s-landing-header__form anim anim-slideup anim-order-5 active": true,
                  },
                },
                [h(landingHeader)]
              );
            },
        }).$mount(".s-landing-header__form");
    }
  },
};

init();

const background_line = gsap.timeline({
  scrollTrigger: {
    trigger: ".js-content-blocks-container",
    start: "top ",
    end: "bottom",
    scrub: true,
  },
});

ScrollTrigger.matchMedia({
  "(min-width: 767px)": () => {
    background_line.to(".js-background-line", { yPercent: -8850, duration: 2 });
  },
});


