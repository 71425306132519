window.addEventListener('DOMContentLoaded', () => {


	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			const id = entry.target.getAttribute('id');
			if (entry.intersectionRatio > 0) {
				document.querySelector(`.s-scroll-a a[href="#${id}"]`).parentElement.classList.add('active');
			} else {
				document.querySelector(`.s-scroll-a a[href="#${id}"]`).parentElement.classList.remove('active');
			}
		});
	}, {rootMargin: "0px 0px 200px 0px"});;

	// Track all sections that have a `class` applied
	[...document.querySelectorAll('.scroll-section')].forEach((section) => {
		observer.observe(section);	
	});
	
});
