var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-lazyimg",
      class: [_vm.imageClass, _vm.imageState, "c-lazyimg"],
    },
    [
      _c("div", {
        staticClass: "c-lazyimg__inner",
        style: _vm.computedStyle,
        attrs: {
          title: _vm.alt,
          "data-width": _vm.imageWidth,
          "data-height": _vm.imageHeight,
          "data-state": _vm.imageState,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }