<template>
	<div
		:class="[{ ie11: isIE, cross: menu }, 'c-burgermenu']"
		ref="container"
		@click="onClick"
		@mouseover="onMouseHover"
		@mouseleave="onMouseLeave"
	>
		<span class="c-burgermenu__text">
			{{ text }}
		</span>
		<span v-for="rows in dots">
			<span v-for="dot in rows" v-bind:style="dot" class="c-burgermenu__dot">
			</span>
		</span>
	</div>
</template>

<script>
import { eventBus } from "../entry";

export default {
	data: function() {
		return {
			isIE: false,
			theme: "black",
			menu: false,
			menuPage: false,
			overRide: false,
			dots: [[{}, {}, {}], [{}, {}, {}], [{}, {}, {}]]
		};
	},
	watch: {
		menu: function() {
			if (this.state !== "") {
				this.theme = "white";
			} else {
				this.theme = "black";
			}
		},
		theme: function() {
			if (this.isMethodPage || this.isProjectsPage || this.isLandingPage) {
				return;
			}
			if (this.theme === "black") {
				document
					.getElementsByClassName("site-header")[0]
					.classList.remove("white");
			} else {
				document
					.getElementsByClassName("site-header")[0]
					.classList.add("white");
			}
		},
		state: function() {
			if (this.state === "") {
				this.onInit();
			}
			if (this.state === "close") {
				this.makeCross();
			}
			if (this.state === "back") {
				this.makeArrow();
			}
		}
	},
	computed: {
		text: function() {
			if (this.overRide) {
				return this.overRide;
			}
			if (this.state === "") {
				return "Menu";
			}
			if (this.state === "close") {
				return "Close";
			}
			if (this.state === "back") {
				return "Back";
			}
		},
		// can be blank, 'close', 'back'
		state: function() {
			var width = document.getElementsByTagName("body")[0].offsetWidth;
			if (this.menu) {
				if (width < 992) {
					// Cause we only want the back state on tablets and mobiles
					if (this.menuPage) {
						return "back";
					} else {
						return "close";
					}
				} else {
					return "close";
				}
			} else {
				return "";
			}
		},
		isMethodPage: function() {
			return document
				.getElementsByTagName("body")[0]
				.classList.contains("method");
		},
		isProjectsPage: function() {
			return document
				.getElementsByTagName("body")[0]
				.classList.contains("projects");
		},
		isLandingPage: function() {
			return document
				.getElementsByTagName("body")[0]
				.classList.contains("landing-page");
		}
	},
	methods: {
		triggerUpdate: function() {
			// Manually triggering update
			this.dots.push([]);
			this.dots.pop();
		},
		makeCross: function() {
			if (!!window.MSInputMethodContext && !!document.documentMode) {
				return;
			}
			var distance = this.$refs.container.offsetWidth / 3;
			var mid = Math.floor(this.dots.length / 2);
			var x = 0;
			var y = 0;
			var offset = distance;
			var style = {};
			for (var i = 0; i < this.dots.length; i++) {
				for (var j = 0; j < this.dots[i].length; j++) {
					style = {
						content: mid,
						top: i * distance + "px",
						left: j * distance + "px"
					};
					if (i == 0 && j == 0) {
						style["height"] = this.$refs.container.offsetWidth + "px";
						style["transform"] =
							"translate(" + offset + "px, -2px) rotate(-45deg)";
					} else if (i == 0 && j == 2) {
						style["transform"] =
							"translate(" + offset * -1 + "px, -2px) rotate(45deg)";
						style["height"] = this.$refs.container.offsetWidth + "px";
					} else {
						style["transform"] = "scale(0)";
					}
					this.dots[i][j] = style;
				}
			}
			this.triggerUpdate();
		},
		makeArrow: function() {
			if (this.isIE) {
				return;
			}
			var width = this.$refs.container.offsetWidth;
			var distance = width / 3;
			var mid = Math.floor(this.dots.length / 2);
			var x = 0;
			var y = 0;
			var offset = distance;
			var style = {};
			for (var i = 0; i < this.dots.length; i++) {
				for (var j = 0; j < this.dots[i].length; j++) {
					style = {
						content: mid,
						top: i * distance + "px",
						left: j * distance + "px"
					};
					if (i == 1 && j == 0) {
						style["width"] = width - width / 4 + "px";
						style["transform"] = "translateX(1px)";
					} else if (i == 0 && j == 0) {
						style["width"] = width / 2 + "px";
						style["transform"] =
							"translateY(" + width / 6 + "px) rotate(-45deg)";
					} else if (i == 2 && j == 0) {
						style["width"] = width / 2 + "px";
						style["transform"] =
							"translateY(" + width / -6 + "px) rotate(45deg)";
					} else {
						style["transform"] = "scale(0)";
					}
					this.dots[i][j] = style;
				}
				``;
			}
			this.triggerUpdate();
		},
		onInit: function() {
			var distance = this.$refs.container.offsetWidth / 3;
			for (var i = 0; i < this.dots.length; i++) {
				for (var j = 0; j < this.dots[i].length; j++) {
					this.dots[i][j] = {
						top: i * distance + "px",
						left: j * distance + "px"
					};
				}
			}
			this.triggerUpdate();
		},
		onMouseHover: function() {
			if (this.isIE) {
				return;
			}
			if (this.overRide === "Close") {
				return;
			}
			if (!this.menu) {
				var distance = this.$refs.container.offsetWidth / 3;
				var mid = Math.floor(this.dots.length / 2);
				var x = 0;
				var y = 0;
				var m = 4;
				for (var i = 0; i < this.dots.length; i++) {
					for (var j = 0; j < this.dots[i].length; j++) {
						if (j > mid) {
							x = m * 1;
						} else if (j < mid) {
							x = m * -1;
						} else {
							x = 0;
						}
						if (i > mid) {
							y = (m + 0) * 1;
						} else if (i < mid) {
							y = (m + 0) * -1;
						} else {
							y = 0;
						}
						this.dots[i][j] = {
							content: mid,
							transform: "translateX(" + x + "px) translateY(" + y + "px)",
							top: i * distance + "px",
							left: j * distance + "px"
						};
					}
				}
				this.triggerUpdate();
			}
		},
		onMouseLeave: function() {
			if (this.overRide === "Close") {
				return;
			}
			if (!this.menu) {
				var distance = this.$refs.container.offsetWidth / 3;
				var x = 0;
				var y = 0;
				for (var i = 0; i < this.dots.length; i++) {
					for (var j = 0; j < this.dots[i].length; j++) {
						this.dots[i][j] = {
							transform: "translateX(" + x + "px) translateY(" + y + "px)",
							top: i * distance + "px",
							left: j * distance + "px"
						};
					}
				}
				this.triggerUpdate();
			}
		},
		onClick: function() {
			eventBus.$emit("burgermenu:click", this.overRide);
		}
	},
	created: function() {
		eventBus.$on("menu:active", obj => {
			this.menu = true;
		});
		eventBus.$on("menu:inactive", obj => {
			this.menu = false;
		});
		eventBus.$on("menupage:active", obj => {
			this.menuPage = true;
		});
		eventBus.$on("menupage:inactive", obj => {
			this.menuPage = false;
		});
		eventBus.$on("planner:active", obj => {
			this.makeCross();
			this.overRide = "Close";
		});
		eventBus.$on("planner:inactive", obj => {
			this.onInit();
			this.overRide = false;
		});
	},
	mounted: function() {
		this.onInit();

		// Check if current browser is IE11
		if (!!window.MSInputMethodContext && !!document.documentMode) {
			this.isIE = true;
		}

		if (this.isProjectsPage) {
			document.getElementsByClassName("site-header")[0].classList.add("white");
		}
	}
};
</script>
