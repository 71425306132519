<template>
	<div :class="[{ active: active }, 's-planner fullheight-col']">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<div class="s-planner__inner">
						<transition>
							<!-- Step 1 -->
							<div class="s-planner__step" v-if="currentStep === 0">
								<p class="p-lead">
									Hi there! Please enter your full name.
								</p>
								<div class="form-group">
									<input
										type="text"
										:class="[{ filled: form.name }]"
										v-model="form.name"
										required="true"
									/>
									<label>
										Full Name
									</label>
								</div>
								<span class="error" v-if="errors.name == 1">
									Name is required
								</span>
								<div class="s-planner__btn-container">
									<div class="btn btn-primary" @click="onClick(null, ['name'])">
										Next
									</div>
								</div>
							</div>
							<!-- Step 1 END -->

							<!-- Step 2 -->
							<div class="s-planner__step" v-if="currentStep === 1">
								<p class="p-lead">
									Hi {{ form.name }}! What's your company name?
								</p>
								<div class="form-group">
									<input
										type="text"
										:class="[{ filled: form.companyName }]"
										v-model="form.companyName"
									/>
									<label>
										Company Name
									</label>
								</div>
								<div class="s-planner__btn-container">
									<div
										class="btn btn-primary btn-primary-outline"
										@click="onClick('prev')"
									>
										Back
									</div>
									<div class="btn btn-primary" @click="onClick()">
										Next
									</div>
								</div>
							</div>
							<!-- Step 2 END -->

							<!-- Step 3 -->
							<div class="s-planner__step" v-if="currentStep === 2">
								<p class="p-lead">
									Great. Briefly describe your project to us.
								</p>
								<div class="form-group">
									<textarea
										type="text"
										:class="[{ filled: form.project }]"
										v-model="form.project"
										required="true"
									></textarea>
									<label>
										Project Description
									</label>
								</div>
								<span class="error" v-if="errors.project == 1">
									Project description is required
								</span>
								<div class="s-planner__btn-container">
									<div
										class="btn btn-primary btn-primary-outline"
										@click="onClick('prev')"
									>
										Back
									</div>
									<div
										class="btn btn-primary"
										@click="onClick(null, ['project'])"
									>
										Next
									</div>
								</div>
							</div>
							<!-- Step 3 END -->

							<!-- Step 4 -->
							<div class="s-planner__step" v-if="currentStep === 3">
								<p class="p-lead">
									Nice! What is your project timeframe?
								</p>
								<div class="form-group">
									<div class="input-range">
										<vue-slider
											ref="slider"
											v-model="form.timeframe"
											:min="1"
											:max="13"
											height="3"
											dot-size="29"
										>
										</vue-slider>
									</div>
									<p class="p-light text-center mt-30">
										{{ timeframeLabel }}
									</p>
								</div>
								<div class="s-planner__btn-container">
									<div
										class="btn btn-primary btn-primary-outline"
										@click="onClick('prev')"
									>
										Back
									</div>
									<div class="btn btn-primary" @click="onClick">
										Next
									</div>
								</div>
							</div>
							<!-- Step 4 END -->

							<!-- Step 5 -->
							<div class="s-planner__step" v-if="currentStep === 4">
								<p class="p-lead">
									What is your estimated investment for our work?
								</p>
								<div class="form-group">
									<div class="input-range">
										<vue-slider
											ref="slider"
											v-model="form.budget"
											:min="10000"
											:max="120000"
											interval="10000"
											height="3"
											dot-size="29"
										>
										</vue-slider>
									</div>
									<p class="p-light text-center mt-30">
										{{ budgetLabel }}
									</p>
								</div>
								<div class="s-planner__btn-container">
									<div
										class="btn btn-primary btn-primary-outline"
										@click="onClick('prev')"
									>
										Back
									</div>
									<div class="btn btn-primary" @click="onClick">
										Next
									</div>
								</div>
							</div>
							<!-- Step 5 END -->

							<!-- Step 6 -->
							<div class="s-planner__step" v-if="currentStep === 5">
								<p class="p-lead">
									Lastly, tell us how we can contact you.
								</p>
								<div class="form-group">
									<input
										type="text"
										:class="[{ filled: form.email }]"
										v-model="form.email"
										required="true"
									/>
									<label>
										Email
									</label>
								</div>
								<span class="error" v-if="errors.email == 1">
									Your email is required
								</span>
								<span class="error" v-if="errors.email == 3">
									Please enter a valid email address
								</span>
								<div class="form-group">
									<input
										type="text"
										:class="[{ filled: form.phone }]"
										v-model="form.phone"
									/>
									<label>
										Phone (optional)
									</label>
								</div>
								<div class="s-planner__btn-container">
									<div
										class="btn btn-primary btn-primary-outline"
										@click="onClick('prev')"
									>
										Back
									</div>
									<div
										class="btn btn-primary"
										@click="onClick(null, ['email'])"
									>
										Next
									</div>
								</div>
							</div>
							<!-- Step 6 END -->

							<!-- Step 7 -->
							<div class="s-planner__step" v-if="currentStep === 6">
								<p class="p-lead text-center">
									Thanks for making the time to contact us.<br />
									We will return your email very shortly.
								</p>
								<div class="s-planner__btn-container mt-15">
									<div
										class="btn btn-primary btn-primary-outline"
										@click="onClose()"
									>
										Close
									</div>
								</div>
							</div>
							<!-- Step 7 END -->
						</transition>
						<div class="s-planner__dots">
							<div
								:class="[{ active: step <= counter }, 's-planner__dot']"
								v-for="step in steps"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "../entry";
import vueSlider from "vue-slider-component";
import axios from "axios";

export default {
	components: {
		vueSlider: vueSlider
	},
	data: function() {
		return {
			active: false,
			counter: 0,
			form: {
				name: "",
				email: "",
				phone: "",
				companyName: "",
				project: "",
				timeframe: 1,
				budget: 10000
			},

			// 0 means error but don't show, 1 means error, 2 means no error
			errors: {
				name: 0,
				email: 0,
				phone: 0,
				companyName: 0,
				project: 0,
				timeframe: 0,
				budget: 0
			},
			steps: [0, 1, 2, 3, 4, 5, 6]
		};
	},
	watch: {
		active: function() {
			if (this.active) {
				eventBus.$emit("planner:active");
				document
					.querySelector(".site-header")
					.classList.add("site-header--planner");
				document.getElementsByTagName("html")[0].classList.add("modal-active");
			} else {
				eventBus.$emit("planner:inactive");
				document
					.querySelector(".site-header")
					.classList.remove("site-header--planner");
				document
					.getElementsByTagName("html")[0]
					.classList.remove("modal-active");
			}
		}
	},
	computed: {
		timeframeLabel: function() {
			var months = "months";
			if (this.form.timeframe == 1) {
				months = "month";
			}
			if (this.form.timeframe > 12) {
				return "More than 12 months";
			} else {
				return "Up to " + this.form.timeframe + " " + months;
			}
		},
		budgetLabel: function() {
			var str = "Up to ";
			if (this.form.budget > 110000) {
				str = "More than ";
			}
			var money = this.formatMoney(this.form.budget);
			return str + money;
		},
		currentStep: function() {
			return this.steps[this.counter];
		}
	},
	methods: {
		onClose: function() {
			this.send();
			this.reset();
			this.active = false;
		},
		onClick: function(dir, arr) {
			if (dir === "prev") {
				this.prevStep();
			} else {
				this.validate(arr);
			}
		},
		prevStep: function() {
			if (this.counter > 0) {
				this.counter -= 1;
			}
		},
		nextStep: function() {
			if (this.counter < this.steps.length) {
				this.counter += 1;
			}
		},
		reset: function() {
			this.counter = 0;
			this.form = {
				name: "",
				email: "",
				phone: "",
				companyName: "",
				project: "",
				timeframe: 1,
				budget: 10000
			};

			this.errors = {
				name: 0,
				email: 0,
				phone: 0,
				companyName: 0,
				project: 0,
				timeframe: 0,
				budget: 0
			};
		},
		validate: function(arr) {
			var result = true;

			if (!arr) {
				this.nextStep();
			} else {
				arr.map(label => {
					if (!this.form[label]) {
						this.errors[label] = 1;
						result = false;
					} else {
						if (label === "email") {
							var isEmail = this.isEmail(this.form[label]);
							if (isEmail) {
								this.errors[label] = 2;
							} else {
								result = false;
								this.errors[label] = 3;
							}
						} else {
							this.errors[label] = 2;
						}
					}
				});

				if (result) {
					this.nextStep();
				}
			}
		},
		isEmail: function(email) {
			// We don't need any fancy email regex here.
			var result = email.indexOf("@");
			if (result === -1) {
				return false;
			} else {
				return true;
			}
		},
		formatMoney: function(money, places, symbol, thousand, decimal) {
			places = !isNaN((places = Math.abs(places))) ? places : 2;
			symbol = symbol !== undefined ? symbol : "$";
			thousand = thousand || ",";
			decimal = decimal || ".";
			var number = money,
				negative = number < 0 ? "-" : "",
				i =
					parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "",
				j = (j = i.length) > 3 ? j % 3 : 0;
			return (
				symbol +
				negative +
				(j ? i.substr(0, j) + thousand : "") +
				i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
				(places
					? decimal +
					  Math.abs(number - i)
							.toFixed(places)
							.slice(2)
					: "")
			);
		},
		send: function() {
			var data = {
				name: this.form.name,
				email: this.form.email,
				phone: this.form.phone,
				companyName: this.form.companyName,
				description: this.form.project,
				timeframe: this.form.timeframe,
				investment: this.form.budget
			};
			var url = document.getElementsByTagName("body")[0].dataset.url;
			url = url + "/wp-json/form/v1/1";
			axios
				.get(url, {
					params: data
				})
				.then(function(res) {
					//console.log(res);
				});
		}
	},
	mounted: function() {
		var btn = document.querySelector(".btn-planner");
		if (btn) {
			btn.addEventListener("click", e => {
				e.preventDefault();
				this.active = true;
			});
		}

		eventBus.$on("burgermenu:click", obj => {
			this.active = false;
		});
	}
};
</script>
