<template>
  <div class="container">
    <div class="s-preview__container">
      <div
        v-if="!carousel"
        class="s-preview__frame__outer"
        v-for="(preview, index) in previews"
      >
        <div class="s-preview__frame anim anim-slideup anim-order-6">
          <div class="s-preview__header" v-if="header[index] !== undefined">
            <img :src="header[index].url" />
          </div>
          <div
            :class="[
              { offset: header[index] !== undefined },
              's-preview__frame__inner',
            ]"
          >
            <img :src="preview.url" />
          </div>
        </div>
      </div>
      <div
        :class="[
          's-preview__button left icon-container',
          { disabled: current === 0 },
        ]"
        v-if="carousel"
        @click="goPrev"
      >
        <span class="icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.452 4.516c0.446 0.436 0.481 1.043 0 1.576l-3.747 3.908 3.747 3.908c0.481 0.533 0.446 1.141 0 1.574-0.445 0.436-1.197 0.408-1.615 0-0.418-0.406-4.502-4.695-4.502-4.695-0.223-0.217-0.335-0.502-0.335-0.787s0.112-0.57 0.335-0.789c0 0 4.084-4.287 4.502-4.695s1.17-0.436 1.615 0z"
              :fill="color"
            ></path>
          </svg>
        </span>
      </div>
      <div
        :class="[
          's-preview__button right icon-container',
          { disabled: current === length - 1 },
        ]"
        v-if="carousel"
        @click="goNext"
      >
        <span class="icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.163 4.516c0.418 0.408 4.502 4.695 4.502 4.695 0.223 0.219 0.335 0.504 0.335 0.789s-0.112 0.57-0.335 0.787c0 0-4.084 4.289-4.502 4.695-0.418 0.408-1.17 0.436-1.615 0-0.446-0.434-0.481-1.041 0-1.574l3.747-3.908-3.747-3.908c-0.481-0.533-0.446-1.141 0-1.576s1.197-0.409 1.615 0z"
              :fill="color"
            ></path>
          </svg>
        </span>
      </div>
      <carousel
        v-if="carousel"
        :per-page="1"
        :mouseDrag="false"
        :pagination-enabled="false"
        @pageChange="onPageChange"
        ref="carousel"
      >
        <slide v-for="preview in previews">
          <div class="s-preview__frame__outer">
            <div class="s-preview__frame">
              <div class="s-preview__header" v-if="header">
                <img :src="header.url" />
              </div>
              <div :class="[{ offset: header }, 's-preview__frame__inner']">
                <img :src="preview.url" />
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  data: function() {
    return {
      header: false,
      carousel: false,
      previews: [],
      color: "#000",
      total: 0,
      length: 3, // Wil be set to the correct value on created()
      current: 0,
    };
  },
  methods: {
    goNext: function() {
      this.$refs["carousel"].goToPage(this.$refs["carousel"].getNextPage());
    },
    goPrev: function() {
      var i = this.$refs["carousel"].getPreviousPage();
      this.$refs["carousel"].goToPage(i);
    },
    onPageChange: function(page) {
      this.current = page;
      this.$forceUpdate();
    },
  },
  created: function() {
    var color = document.querySelector(".s-preview__data").dataset.color;
    var data = document.querySelector(".s-preview__data");
    if (data) {
      data = data.text || "[]";
    }
    var header = document.querySelector(".s-preview__header");

    if (header) {
      //header = header.text || "[]";
      this.header = JSON.parse(header.innerHTML);
    }
    data = JSON.parse(data);
    //this.header = header;
    this.length = data.length;
    this.current = 0;
    this.previews = data;
    var width = document.body.clientWidth;
    if (width < 992) {
      this.carousel = true;
    }
    //this.color = color;
  },
  mounted: function() {},
};
</script>
