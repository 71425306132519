/**
 * Testimonials Box Template Part Scripts
 */

import { tns } from "tiny-slider/src/tiny-slider";

const tinyCarousel = () => {
  const commonTestimonialWrapper = document.querySelectorAll(".js-testimonial");

  [...commonTestimonialWrapper].forEach((slider) => {
    // If you need to use custom controls container uncomment line below, else remove it
    tns({
      container: slider,
      items: 1,
      mouseDrag: true,
      lazyload: true,
      nav: true,
      loop: true,
      controls: true,
      autoplay: false,
      speed: 700,
      controlsText: [
        "<svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'><title>icon__chevron-regular-left--black-color</title><path d='M14.9822 3.95443L0 11.5996L14.9822 19.2448L12.8478 11.5996L14.9822 3.95443Z' fill='#fff'/></svg>",
        "<svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8.01776 19.0456L23 11.4004L8.01777 3.75521L10.1522 11.4004L8.01776 19.0456Z' fill='#fff'/></svg>",
      ],
    });
  });
};

if (document.querySelector(".js-testimonial")) {
  tinyCarousel();
}
