<template>
	<div class="c-sprite" :class="[imageState]" ref="root">
		<div
			class="c-sprite__inner"
			:style="computedInnerStyle"
			:data-width="imageWidth"
			:data-height="imageHeight"
			:data-state="imageState"
		>
			<div class="c-sprite__image" ref="imageEl" :style="computedStyle"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		imageSource: {
			type: String
		},
		imageErrorCallback: {
			type: Function,
			required: false,
			default: function() {}
		},
		imageSuccessCallback: {
			type: Function,
			required: false,
			default: function() {}
		},
		currentDivision: {
			type: Number,
			required: false,
			default: 1
		},
		divisions: {
			default: 2
		},
		resized: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: function() {
		return {
			state: 0,
			imageState: "loading",
			imageWidth: 0,
			imageHeight: 0,
			asyncImage: new Image(),
			backgroundSize: "contain",
			direction: "v",
			rootDimensions: {
				width: 0,
				height: 0
			}
		};
	},
	watch: {
		resized: function() {
			this.updateRootDimensions();
		}
	},
	computed: {
		computedStyle: function() {
			var obj = this.computeDimensions();
			var style = {
				width: obj.width + "px",
				height: obj.height + "px"
			};
			var currentDivision = this.currentDivision;
			var offsets = this.computeOffsets(currentDivision, obj);
			style["top"] = offsets.y + "px";
			style["left"] = offsets.x + "px";
			if (this.imageState === "loaded") {
				style["background-image"] = "url(" + this.asyncImage.src + ")";
				style["background-size"] = this.backgroundSize;
			}
			return style;
		},
		computedInnerStyle: function() {
			var style = {};
			return style;
		}
	},
	methods: {
		updateRootDimensions: function() {
			if (this.$refs.root) {
				this.rootDimensions = {
					width: this.$refs.root.offsetWidth,
					height: this.$refs.root.offsetHeight
				};
			} else {
				this.rootDimensions = {
					width: 0,
					height: 0
				};
			}
		},
		computeOffsets: function(division, obj) {
			var divisions = this.divisions;
			var offset = Math.round(division / divisions);
			var offsetX = 0;
			var offsetY = 0;
			var segment = 0;
			var height = 0;
			var bias = -1;
			if (this.direction === "h") {
				segment = this.imageWidth / this.divisions;
				offsetX =
					(offset * this.rootDimensions.width + this.rootDimensions.width) *
					bias;
				offsetY = 0;
			} else {
				if (this.$refs.imageEl) {
					segment = this.$refs.imageEl.offsetHeight / this.divisions;
				}
				offsetY = (segment * division - segment) * bias;
				offsetX = 0;
			}
			return {
				x: offsetX,
				y: offsetY
			};
		},
		computeDimensions: function() {
			var obj = {
				width: 0,
				height: 0
			};
			if (this.direction === "h") {
				obj.height = this.rootDimensions.height;
				obj.width =
					(this.imageWidth * this.rootDimensions.height) / this.imageHeight;
			} else {
				obj.width = this.rootDimensions.width;
				obj.height =
					(this.imageHeight * this.rootDimensions.width) / this.imageWidth;
			}

			return obj;
		},
		fetchImage(url) {
			if (!this.imageSource) {
				return;
			}
			this.asyncImage.onload = this.imageOnLoad;
			this.asyncImage.onerror = this.imageOnError;
			this.imageState = "loading";
			this.asyncImage.src = this.imageSource;
			this.$forceUpdate();
		},
		imageOnLoad(success) {
			this.imageState = "loaded";
			this.imageWidth = this.asyncImage.naturalWidth;
			this.imageHeight = this.asyncImage.naturalHeight;
			this.imageSuccessCallback();
		},
		imageOnError(error) {
			console.log(error);
			this.imageState = "error";
			this.imageErrorCallback();
		}
	},
	created: function() {},
	mounted: function() {
		this.updateRootDimensions();
		this.$nextTick(() => {
			this.fetchImage();
		});
	}
};
</script>
