<template>
	<div class="s-projects__slider" ref="root">
		<div class="s-projects__inner">
			<!-- COVERS -->
			<div class="s-projects__covers">
				<lazyimg
					v-for="(project, i) in projects"
					:imageClass="[{ active: currentIndex === i }]"
					:imageSource="project.thumbnail.src"
					:alt="project.thumbnail.alt"
					:srcset="project.thumbnail.srcset"
					:sizes="project.thumbnail.sizes"
				/>
			</div>
			<!-- COVERS END -->

			<!-- SLIDE -->
			<!--
			<transition name="fadeout">
				<carousel
					v-if="active"
					:per-page="1"
					:pagination-enabled="false"
					:navigate-to="navto"
					ref="carousel"
					:autoplay="true"
					:autoplay-timeout="5000"
					easing="cubic-bezier(0.645, 0.045, 0.355, 1.000)"
					speed="1000"
					@pageChange="onPageChange"
				>
					<slide v-for="project in projects">
						<div class="s-projects__slide fullheight-col">
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="vertically-align">
											<p class="p-bold fadeout">
												{{ project.company_name }}
											</p>
											<div class="row">
												<div class="col-12 col-md-10 col-lg-8">
													<h2 class="h1 mb-30 mb-lg-50 fadeout">
														{{ project.title }}
													</h2>
												</div>
											</div>
											<a
												:href="project.permalink"
												class="btn btn-primary fadeout fadeout-late"
											>
												Read project
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</slide>
				</carousel>
			</transition>
		-->
			<!-- SLIDE END -->

			<!-- New -->
			<slick
				ref="slick"
				:options="slickOptions"
				@afterChange="handleAfterChange"
			>
				<div
					class="s-projects__slide fullheight-col"
					v-for="project in projects"
				>
					<div class="s-projects__slide fullheight-col">
						<div class="container">
							<div class="row">
								<div class="col-12">
									<div class="vertically-align">
										<p class="fadeout mb-30 breadcrumb">
											<span class="p-bold">Project</span
											><span>&nbsp;/&nbsp;</span>{{ project.company_name }}
										</p>
										<div class="row">
											<div class="col-12 col-md-10 col-lg-8">
												<a class="link" :href="project.permalink">
													<h2 class="h1 mb-30 mb-lg-50 fadeout">
														{{ project.title }}
													</h2>
												</a>
											</div>
										</div>
										<div class="fadeout fadeout-late">
											<a
												:href="project.permalink"
												class="btn btn-tertiary btn-tertiary-white"
											>
												Read project
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</slick>
			<!-- New END -->
		</div>
		<nav class="s-projects__nav">
			<a
				href="#"
				v-for="(project, i) in projects"
				:key="i"
				:class="[{ active: i === currentIndex }]"
				@click="onNavClick(i)"
			>
			</a>
		</nav>
	</div>
</template>

<script>
import lazyimg from "../components/lazyImg";
import { Carousel, Slide } from "vue-carousel";
import Slick from "vue-slick";

export default {
	components: {
		Slick,
		lazyimg,
		Carousel,
		Slide
	},
	data: function() {
		return {
			slickOptions: {
				accessibility: false,
				slidesToShow: 1,
				vertical: true,
				draggable: true,
				arrows: false,
				dots: true,
				speed: 1000,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							vertical: false
						}
					}
				]
			},
			active: false,
			navto: 0,
			direction: "top",
			currentIndex: 0,
			accessibility: false,
			lastScroll: 0
		};
	},
	props: ["projects"],
	computed: {},
	watch: {
		navto: function() {
			this.$refs.slick.goTo(this.navto);
		}
	},
	methods: {
		handleAfterChange: function(data, id) {
			this.onPageChange(id.currentSlide);
		},
		onNavClick: function(i) {
			this.navto = i;
		},
		onPageChange: function(i) {
			this.currentIndex = i;
		},
		goPrev: function() {
			this.$refs.slick.prev();
		},
		goNext: function() {
			this.$refs.slick.next();
		},
		onKeyPress: function(e) {
			if (e.keyCode === 37) {
				this.goPrev();
			}
			if (e.keyCode === 39) {
				this.goNext();
			}
		},
		onScroll: function(e) {
			console.log(window.scrollY);
		}
	},
	mounted: function() {
		this.active = true;

		document.getElementsByClassName("slick-slider")[0].focus();
		window.addEventListener("keydown", e => {
			this.onKeyPress(e);
		});
		document.addEventListener("wheel", event => {
			const delta = Math.sign(event.deltaY);
			if (delta === 1) {
				this.goNext();
			}
			if (delta === -1) {
				this.goPrev();
			}
		});
	}
};
</script>
