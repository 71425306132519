var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [{ active: _vm.active }, "s-profiles"] },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "s-profiles__container" },
          _vm._l(_vm.people, function (person, index) {
            return _c(
              "div",
              {
                ref: "person-" + index,
                refInFor: true,
                class: [
                  "s-profiles__profile",
                  { blank: person.angle === null },
                ],
                on: {
                  click: function ($event) {
                    return _vm.onClick(person)
                  },
                },
              },
              [
                _c("div", {
                  ref: "person-box-" + index,
                  refInFor: true,
                  staticClass: "s-profiles__bounding-box",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "s-profiles__profile__image",
                      "anim anim-slideup",
                      { mixblendmode: _vm.mixBlendMode },
                    ],
                  },
                  [
                    _c("sprite", {
                      attrs: {
                        resized: _vm.resized,
                        imageSource: person.avatar,
                        divisions: "13",
                        currentDivision: person.angle + 1,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "s-profiles__profile__info" }, [
                  _c("strong", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" + _vm._s(person.name) + "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  person.role
                    ? _c("em", { staticClass: "p-small" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(person.role) +
                            "\n\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "modal-profiles",
            width: "100%",
            height: "auto",
            scrollable: true,
          },
          on: { closed: _vm.onModalClose, opened: _vm.onModalOpen },
        },
        [
          _vm.currentProfile
            ? _c("div", [
                _c("div", { staticClass: "close" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("modal-profiles")
                        },
                      },
                    },
                    [
                      _vm._v("\n\t\t\t\t\tClose\n\t\t\t\t\t"),
                      _c("span", {
                        staticClass: "icon icon-white-close icon-xs",
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "container" }, [
                  _vm.currentProfile.angle
                    ? _c("h3", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.currentProfile.name) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.currentProfile.angle
                    ? _c("h3", [_vm._v("\n\t\t\t\t\tJoin Us\n\t\t\t\t")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentProfile.angle
                    ? _c("span", { staticClass: "p-lead" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.currentProfile.role) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.currentProfile.angle
                    ? _c("article", { staticClass: "mt-30" }, [
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t\tWe'd like to hear from you\n\t\t\t\t\t"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t\tWe're immensely proud of our collaborative culture, trust and\n\t\t\t\t\t\tempowerment and are always on the lookout for talented A-player\n\t\t\t\t\t\tindividuals to join our amazing team. Our studio is located in\n\t\t\t\t\t\tSouth Melbourne surrounded by great coffee and friends. So if you\n\t\t\t\t\t\tare a marketing or digital changemaker, we’d love to hear from\n\t\t\t\t\t\tyou. Send your CV with recent work samples to\n\t\t\t\t\t\tjobs@pluralagency.com.au\n\t\t\t\t\t"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("article", {
                    staticClass: "mt-30",
                    domProps: { innerHTML: _vm._s(_vm.currentProfile.content) },
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("resize-observer", { on: { notify: _vm.handleResize } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }