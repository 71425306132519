var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      class: [{ ie11: _vm.isIE, cross: _vm.menu }, "c-burgermenu"],
      on: {
        click: _vm.onClick,
        mouseover: _vm.onMouseHover,
        mouseleave: _vm.onMouseLeave,
      },
    },
    [
      _c("span", { staticClass: "c-burgermenu__text" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.text) + "\n\t"),
      ]),
      _vm._v(" "),
      _vm._l(_vm.dots, function (rows) {
        return _c(
          "span",
          _vm._l(rows, function (dot) {
            return _c("span", { staticClass: "c-burgermenu__dot", style: dot })
          }),
          0
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }