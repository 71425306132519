(function (window) {
    "use strict";

    const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

    const animationWrapper = document.querySelector('#s-intro');
    const introArrow = document.querySelector('.s-intro__arrow ');

    if(introArrow) {
    const introArrowWidth = introArrow.getBoundingClientRect().width;
    const introHalfWidth = introArrowWidth / 2;

    const features = Array.from(document.querySelectorAll('.s-intro__features-inner'));
    const coordRotateLeft = features[0].getBoundingClientRect().width + features[0].getBoundingClientRect().x;
    const coordRotateRight = features[2].getBoundingClientRect().x;

    const sideBorder = 100;
    const minCoordX = features[0].getBoundingClientRect().width  + features[0].getBoundingClientRect().x - introHalfWidth  + sideBorder;
    const maxCoordX = features[2].getBoundingClientRect().width + features[2].getBoundingClientRect().x - introHalfWidth - sideBorder;

    const maxCoordY = animationWrapper.getBoundingClientRect().y + animationWrapper.getBoundingClientRect().height;

    const windowW = animationWrapper.getBoundingClientRect().width;
    const cursorWidth = 470;
    const xLeft = animationWrapper.getBoundingClientRect().width / 2 - introHalfWidth;

    introArrow.style.opacity = ".75";


        const cursor = {
        el: introArrow,
        x: windowW >> 1,
    };

    const target = {
        x: windowW >> 1,
        y: maxCoordY >> 1,
        width: cursorWidth,
        followMouse: true,
    };

    const loop = (e) => {
        const destX = target.x;
        const destY = target.y;
        const newX = cursor.x + ((destX - cursor.x) );

        let rotateAmount;
        let newXcoord;

     if(e.clientY > maxCoordY) {
            onPointerOut();
        }

        else if(newX < maxCoordX && newX > minCoordX){
            newXcoord = newX - introHalfWidth;

            if(cursor.x <  coordRotateLeft) {
                rotateAmount = -30
            }
            else if(cursor.x > coordRotateRight) {
                rotateAmount = 30;
            }
            else {
                rotateAmount = 0;
            }

            cursor.x = newX ;

        } else if (newX < minCoordX && e.clientY < maxCoordY) {
            newXcoord = minCoordX - introHalfWidth;
            rotateAmount = -30
            cursor.el.style.transform = `rotate(-30deg) translateX(0%)`;
        } else if (newX > maxCoordX && e.clientY < maxCoordY) {
            newXcoord = maxCoordX - introHalfWidth;
            rotateAmount = 30;
        }

        cursor.el.style.transform = `rotate(${rotateAmount}deg) translateX(0%)`;
        cursor.el.style.left =  newXcoord + "px";

        requestAnimationFrame(loop);
    };


    const onPointerMove = (e) => {
        if (!target.followMouse) {
            return;
        }
        cursor.el.style.transition = "";
        const pointer = (e.touches && e.touches.length) ? e.touches[0] : e;
        const {clientX: x, clientY: y} = pointer;

        target.x = x;
        target.y = y;
        loop(e);
    };

    const onPointerOut = () => {
        cursor.el.style.transform = `rotate(0deg)`;
        const xLeft = animationWrapper.getBoundingClientRect().width / 2 - introHalfWidth;

        cursor.el.style.left = xLeft + "px";
        cursor.el.style.transition = ".5s left ease-in-out";

        cancelAnimationFrame(loop);
        animationWrapper.removeEventListener('mousemove', onPointerMove)
    };

    const onMouseEnter = () => {
        animationWrapper.addEventListener('mousemove', onPointerMove)
    }

    const onResize = () => {
        onPointerOut();
    }

        animationWrapper.addEventListener('mouseenter', onMouseEnter);
        //animationWrapper.addEventListener('mousemove', onPointerMove);
        animationWrapper.addEventListener('mouseleave', onPointerOut);
        window.addEventListener('resize', onResize);
    }


})(window);
