<template>
	<div class="c-lazyimg" :class="[imageClass, imageState, 'c-lazyimg']">
		<div
			class="c-lazyimg__inner"
			:title="alt"
			:style="computedStyle"
			:data-width="imageWidth"
			:data-height="imageHeight"
			:data-state="imageState"
		></div>
	</div>
</template>

<script>
export default {
	props: {
		imageSource: {
			type: String,
			required: true
		},
		imageClass: {
			required: false,
			default: ""
		},
		loadingImage: {
			type: String,
			required: false
		},
		errorImage: {
			type: String,
			required: false
		},
		alt: {
			type: String,
			required: false,
			default: ""
		},
		/**
		 * Technically, sizes and srcset are useless cause we're using a div and not a img
		 * @type {Object}
		 */
		sizes: {
			type: String,
			required: false,
			default: ""
		},
		srcset: {
			type: String,
			required: false,
			default: ""
		},
		imageErrorCallback: {
			type: Function,
			required: false,
			default: function() {}
		},
		imageSuccessCallback: {
			type: Function,
			required: false,
			default: function() {}
		},
		backgroundSize: {
			type: String,
			required: false,
			default: "cover"
		}
	},
	data: function() {
		return {
			imageState: "loading",
			imageWidth: 0,
			imageHeight: 0,
			asyncImage: new Image()
		};
	},
	computed: {
		computedStyle: function() {
			if (this.imageState === "loading") {
				return ""; //'background-image: url(' + this.loadingImage + ');'
			}
			if (this.imageState === "error") {
				return ""; //'background-image: url(' + this.errorImage + ');'
			}
			if (this.imageState === "loaded") {
				return (
					"background-image: url(" +
					this.asyncImage.src +
					"); background-size: " +
					this.backgroundSize
				);
			}
			return "";
		}
	},
	methods: {
		fetchImage(url) {
			if (!this.imageSource) {
				return;
			}
			this.asyncImage.onload = this.imageOnLoad;
			this.asyncImage.onerror = this.imageOnError;
			this.imageState = "loading";
			this.asyncImage.src = this.imageSource;
		},
		imageOnLoad(success) {
			this.imageState = "loaded";
			this.imageWidth = this.asyncImage.naturalWidth;
			this.imageHeight = this.asyncImage.naturalHeight;
			this.imageSuccessCallback();
		},
		imageOnError(error) {
			this.imageState = "error";
			this.imageErrorCallback();
		}
	},
	created: function() {},
	mounted: function() {
		this.$nextTick(() => {
			this.fetchImage();
		});
	}
};
</script>
