import { jarallax } from "jarallax";

(function(window) {
  function startJarallax(x) {
    if (x.matches) {
      jarallax(document.querySelectorAll(".js-jarallax"), {
        speed: 0.2,
      });
    }
  }

  const mediaQuery = window.matchMedia("(min-width: 996px)");
  mediaQuery.addListener(startJarallax);
  startJarallax(mediaQuery);
})(window);
